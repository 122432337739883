.category-navigation-menu-container {
  position: fixed;
  z-index: 200;
  height: 120px;
  .rtl & {
    right: 20px;
  }
  .ltr & {
    left: 20px;
  }
  .top-ellipse {
    width: 30px;
    height: 12px;
    // background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    background: var(--category-background-color);
    border-radius: 50px;
    margin-bottom: 5px;
  }
  .category-name-conatiner {
    margin-bottom: 5px;
    .circle {
      width: 30px;
      height: 30px;
      // background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
      background: var(--category-background-color);
      box-shadow: 0px 4px 4px rgba(111, 111, 111, 0.2);
      border-radius: 50px;
      z-index: 20;
    }
    .name-circle {
      width: fit-content;
      height: 20px;
      .rtl & {
        margin-right: -20px;
      }
      .ltr & {
        margin-left: -20px;
      }
      .category-name-button {
        // background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
        background: var(--category-background-color);
        border-radius: 50px;
        padding: 5px 20px;
        &.menu-close {
          border: none;
        }
        .text {
          font-size: 14px;
          color: black;
          letter-spacing: 1px;
          font-weight: bold;
          .rtl & {
            margin-right: 5px;
          }
          .ltr & {
            margin-left: 5px;
          }
        }
      }
      .category-nanvigation-buttons-container {
        background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
        // background: var(--category-background-color);
        border-radius: 50px;
        justify-content: space-evenly;
        margin: 0 5px;
        .button {
          height: 30px;
          width: 65px;
          padding: 0 10px;
          background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
          //   background: var(--category-background-color);
          border-radius: 50px;
          font-size: 12px;
          text-align: center;
          border: none;
          .icon {
            .rtl & {
              transform: scaleX(-1);
            }
          }
        }
      }
    }
  }
  .bottom-center {
    width: 20px;
    height: 20px;
    // background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    background: var(--category-background-color);
    border-radius: 50px;
    margin: 0 5px 5px 5px;
  }
}
