.define-yourself-container {
  text-align: center;
  margin: 40px 0 0 0;
  .text {
    font-size: 15px;
    color: #015568;
    margin-bottom: 40px;
  }
  .ratings-container {
    margin-bottom: 100px;
  }
  .dont-ask-me-again-text {
    &:hover {
      cursor: pointer;
    }
  }
}
