.status-level-up-popup-container {
  .header-container {
    flex-direction: column;
    top: 0;
    padding: 10px 0;
    width: 100%;
    margin-top: 20px;
    gap: 10px;
    .status-image {
      width: 15px;
      height: 15px;
    }
  }

  .status-text {
    display: flex;
    justify-content: center;
    font-size: 30px;
    h4 {
      margin: 0;
      padding: 0;
    }
  }

  .cloud-icon-container-separate {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .rtl & {
      margin-right: 50px;
    }
    .ltr & {
      margin-left: 50px;
    }
    .cloud {
      width: 200px;
      height: 180px;
    }
    .icon-expert {
      transform: translate(-70px, -160px);
      width: 50px;
      height: 50px;
    }
    .icon-legend {
      transform: translate(-60px, -25px);
      width: 40px;
      height: 40px;
    }
  }
  .cloud-icon-container {
    display: flex;
    justify-content: center;
    .cloud {
      width: 200px;
      height: 200px;
    }
  }
  .points-info {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    h5 {
      margin: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      font-size: 18px;
      color: #015568;
    }
    .status-icon {
      vertical-align: middle;
      width: 20px;
    }
  }
}
