.moving-along-popup-container {
  height: 403px;
  background-image: url("../../../../../assets/icons/person2.svg"),
    url("../../../../../assets/icons/cloud.svg"),
    url("../../../../../assets/icons/cloud.svg"),
    url("../../../../../assets/icons/cloud.svg");
  background-position: 101px 102px, 157px 131px, 100px 179px, 243px 197px;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: auto, 89px 26px, 58px 17px, 58px 17px;
  .headers {
    flex-direction: column;
    margin-top: 20px;
  }
  .footer-button-container {
    position: relative;
    top: 290px;
    .footer-button {
      width: 70%;
    }
  }
}
