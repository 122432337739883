.share-step-container {
  flex-direction: column;
  position: relative;
  top: 38px;
  .v-circle-icon {
    margin-top: -20px;
  }
  .text {
    width: 254px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #015568;
    margin: 10px 0 20px 0;
  }
  .boosters-example-icon {
    height: 170px;
    margin-top: 10px;
  }
  .share-button {
    width: 225px;
    margin-bottom: 20px;
  }
  .boosters-example-img {
    margin-top: 20px;
    width: 100%;
  }
}
