.duplicate-content-container {
  .duplicate-content-title {
    padding-bottom: 20px;
  }

  .delete-content-from-country-info {
    display: flex;
    justify-content: center;
  }

  .save-container {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    .action-button {
      margin: 2px;
    }
  }
}
