.categories-container {
  text-align: center;
  h1 {
    text-align: center;
  }
  .categories-title {
    text-align: left;
    padding: 0 10px;
    font-size: 15px;
    line-height: 16px;
    color: #015568;
  }
  h4 {
    margin: 0;
    padding: 0;
  }

  .categories-sliders {
    margin: 0 0 0 0;
  }
  .category-title {
    justify-content: space-between !important;
    align-items: flex-end !important;
    padding-bottom: 5px;
    width: 110%;
    span {
      .rtl & {
        padding-left: 10px;
      }
      .ltr & {
        padding-right: 10px;
      }
    }
    .svg-icon {
      height: 30px;
      margin: 0 25px;

      .rtl & {
        padding-left: 10px;
      }
      .ltr & {
        padding-right: 10px;
      }
    }
  }
  .other-button {
    min-width: 100%;
    width: 317px;
    height: 49px;
    justify-content: space-between !important;
    background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 53px;
    margin: 20px 0 20px 0;
    border: none;
    p {
      .rtl & {
        padding-left: 120px;
      }
      .ltr & {
        padding-right: 120px;
      }
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #014352;
    }
    svg {
      .rtl & {
        padding-right: 20px;
      }
      .ltr & {
        padding-left: 20px;
      }
      path {
        fill: url(#b);
      }
    }
  }
}
