.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  position: fixed;
  background: #ffffffc7;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  left: 0;
}
.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  color: green;
  vertical-align: middle;
  pointer-events: none;
  border: 0.2em solid transparent;
  border-top-color: currentcolor;
  border-radius: 50%;
  animation: 1s loader-05 linear infinite;
  position: relative;

  &.page {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20%;
    left: 45%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  &:before {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    top: -0.2em;
    left: -0.2em;
    border: 0.2em solid currentcolor !important;
    border-radius: 50%;
    opacity: 0.5;
  }
}

@keyframes loader-05 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
