.view-main-goal-popup-container {
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  .header {
    margin-bottom: 10px;
  }
  .goal {
    margin: 0;
    text-align: center;
  }
}
