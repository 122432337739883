.map-card-container {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgb(0 0 0 / 20%);
  max-width: 100%;
  overflow: hidden;
  border: solid 1px #2a265f;
  hr {
    padding: 0px;
    margin: 0px;
  }
  * {
    box-sizing: border-box;
  }
  .header {
    justify-content: flex-start !important;
    padding: 10px;
    width: 100%;
    h6 {
      opacity: 0.6;
      margin: 0;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .date-and-name-conatiner {
      height: 50px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      width: 150px;
    }
    h4 {
      margin: 0;
      text-align: initial;
    }
    .map-image {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 1px black solid;
      .rtl & {
        margin-left: 10px;
      }
      .ltr & {
        margin-right: 10px;
      }
    }
    .progress-container {
      align-self: flex-start;
      position: relative;
      text-align: center;
      .rtl & {
        margin-right: auto;
      }
      .ltr & {
        margin-left: auto;
      }
      .completed-icon {
        height: 18px;
        color: #2a265f;
      }
      .progress-zero-step {
        background-color: lightgray;
        border-radius: 3px;
        height: 5px;
        width: 50px;
      }
      .progress-zero-step::after {
        border-radius: 3px;
        background-color: #2a265f;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        width: 0;
      }
      .progress-first-step {
        background-color: lightgray;
        border-radius: 3px;
        height: 5px;
        width: 50px;
      }
      .progress-first-step::after {
        border-radius: 3px;
        background-color: #2a265f;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        width: 33%;
      }
      .progress-second-step {
        background-color: lightgray;
        border-radius: 3px;
        height: 5px;
        width: 50px;
      }
      .progress-second-step::after {
        border-radius: 3px;
        background-color: #2a265f;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        width: 66%;
      }
      .progress-text {
        font-size: 10px;
        opacity: 0.6;
        letter-spacing: 1px;
      }
    }
  }
  .footer {
    padding: 10px;
    position: relative;
    width: 100%;
    .edit-and-remove-icons {
      .rtl & {
        margin-left: auto;
      }
      .ltr & {
        margin-right: auto;
      }
      .icon {
        color: #2a265f;
        height: 22px;
      }
      .icon:first-child {
        .rtl & {
          margin-left: 10px;
        }
        .ltr & {
          margin-right: 10px;
        }
      }
    }
    .view-map-button {
      justify-content: space-between !important;
      width: 155px;
      height: 35px;
      background-color: #2a265f;
      border: 0;
      border-radius: 50px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
      color: #fff;
      font-size: 16px;
      padding: 12px 25px;
    }
  }
}
