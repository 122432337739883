.popup-button {
  width: fit-content;
  padding: 0 20px;
  min-height: 42px;
  background: linear-gradient(180deg, #00e8e1 0%, #00e37f 100%);
  border-radius: 20px;
  font-weight: 700;
  font-size: 17px;
  color: #015568;
  display: initial;
  border: none;
  &.continue {
    background: linear-gradient(90deg, #00e37f 17%, #00ebe1 85%);
  }
}
