.maps-container {
  .add-new-button {
    width: 75%;
    height: 45px;
    margin: 20px 0;
  }
  .add-new-button-icon {
    .rtl & {
      margin-right: 5px;
    }
    .ltr & {
      margin-left: 5px;
    }
  }
  .category-container {
    .category-header {
      justify-content: flex-start !important;
      .category-icon {
        width: 35px;
        height: 35px;
      }
      .category-name {
        flex-direction: column;
        font-weight: bold;
        letter-spacing: -0.5px;
        color: #014352;
        font-size: 20px;
        .rtl & {
          margin-right: 10px;
        }
        .ltr & {
          margin-left: 10px;
        }
      }
    }
  }

  .map-container {
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      margin: 0;
      padding: 0;
    }
    .right-container {
      display: flex;
      flex-direction: column;

      .map-buttons-container {
        display: flex;

        .map-button {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          padding: 0 10px;
          background-color: white;
          padding: 6px 12px;
          line-height: 1.42857143;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          -ms-touch-action: manipulation;
          touch-action: manipulation;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background-image: none;
          border-radius: 4px;
          &:hover {
            color: #333;
            background-color: #e6e6e6;
            border-color: #adadad;
          }
        }
        .separator-line {
          border-left: 2px solid black;
          margin: 0 10px;
        }
      }
    }
  }
}
