.create-name-and-image-container {
  margin-top: 20px;
  h4,
  p {
    margin: 0;
    padding: 0;
  }
  .goal-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 21px;
    color: #014352;
    margin-bottom: 8px;
  }
  .image-container {
    text-align: center;
    .choose-image {
      height: 47px;
      background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
      border-radius: 30px;
      .text {
        width: 163px;
        font-weight: 700;
        font-size: 20px;
        color: #014352;
        .rtl & {
          margin-right: 10px;
        }
        .ltr & {
          margin-left: 10px;
        }
      }
    }
  }
  .missing-pathway-message {
    font-size: 16px;
    margin-top: 10px;
  }

  .goal-image {
    width: 250px;
    height: 250px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px black solid;
  }
  .goal-buttons {
    padding-bottom: 20px !important;
  }
}
