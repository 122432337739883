.welcome-back-container {
  margin: 20px 0;
  flex-direction: column;
  .headers {
    flex-direction: column;
    margin-bottom: 20px;
  }
  text-align: center;
  h1 {
    text-align: center;
  }

  h4,
  h3 {
    margin: 0;
    padding: 0;
  }
  .stats-card {
    flex-direction: row;
    width: 180px;
    justify-content: space-between;
    margin: 10px 0;
    font-weight: bold;
    .header-text-and-icon {
      .text {
        .rtl & {
          margin-right: 10px;
        }
        .ltr & {
          margin-left: 10px;
        }
      }
    }
    .value-text {
      font-size: 24px;
    }
  }
  .continue-button {
    margin-top: 20px;
  }

  .journey-preview {
    .rtl & {
      padding-top: 10px;
    }
  }
}
