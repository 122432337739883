.footer-menu-button-container {
  height: 80px;
  margin-right: 10px;
  .circle-icon {
    height: 45px;
    width: 45px;
    background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    border-radius: 50%;
    text-align: center;
  }
  .text {
    font-size: 12px;
    max-width: 35px;
    text-align: center;
    margin: auto;
  }
}
@media only screen and (max-width: 320px) {
  .footer-menu-button-container {
    .circle-icon {
      height: 35px;
      width: 35px;
    }
  }
}
