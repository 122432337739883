.button {
  user-select: none;
  width: fit-content;
  width: 50px;
  height: 52px;
  border: none;
  text-align: center;
  background: linear-gradient(89.93deg, #00e37f 4.49%, #00ebe1 83.69%);
  box-shadow: 0px 10px 16px rgba(23, 50, 72, 0.24),
    0px 2px 6px rgba(23, 50, 72, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 123px;
  font-weight: 700;
  font-size: 20px;
  color: #015568;
  cursor: "pointer";
  &.small {
    font-size: 17px;
    color: #014352;
    width: 102px;
    height: 35px;
  }
  &.disabled {
    pointer-events: none;
  }
  .fa-spin {
    font-size: 22px;
  }
  &:active {
    background: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
}
