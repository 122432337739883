.leisure-and-community-life-container {
  text-align: center;
  h1 {
    text-align: center;
  }

  h4 {
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 320px) {
  .leisure-and-community-life-container {
    margin-top: 40px;
  }
}
