.share-booster-popup-container {
  margin: 70px 30px 30px 30px;
  flex-direction: column;
  .header {
    margin-bottom: 50px;
  }
  .share-with-container {
    margin-bottom: 30px;
  }
  .close-button {
    margin-top: 80px;
    width: 90%;
  }
}
