.read-more-popup-container {
  width: auto;
  text-align: center;
  color: #015568;
  padding: 10px 50px;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  }
  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 0;
  }
  p {
    font-size: 12px;
    margin: 0 !important;
  }
}
.read-more-button-wrapper {
  margin: 20px;
  .button {
    width: 100% !important;
  }
}
