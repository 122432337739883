.category-button {
  justify-content: flex-start !important;
  width: 147px;
  height: 49px;
  background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 53px;
  margin-bottom: 14px;

  .icon {
    height: 40px;
    .rtl & {
      margin-right: 10px;
      margin-left: 10px;
    }
    .ltr & {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .text {
    max-width: 92px;
    word-wrap: break-word;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 137% */

    color: #014352;
  }
  .edit-icons {
    flex-direction: column;
    justify-content: space-between;
    svg {
      padding: 2px 0;
      .rtl & {
        padding-right: 5px;
      }
      .ltr & {
        padding-left: 5px;
      }
    }
    .rtl & {
      margin-left: 10px;
    }
    .ltr & {
      margin-right: 10px;
    }
  }
}
