.share-buttons-container {
  width: 100%;
  justify-content: flex-start !important;
  .share-button {
    .rtl & {
      margin-left: 5px;
    }
    .ltr & {
      margin-right: 5px;
    }
  }
}
