.menu {
  display: flex;
  .rtl & {
    direction: rtl;
  }
  .menu-text-header {
    position: fixed;
    top: 8px;
    color: #60e4b3 !important;
  }
  .menu-header {
    display: flex !important;
    // padding: 0 0 20px 0 !important;
    margin: 30px 0 0 0 !important;
    .profile-image {
      border-radius: 50%;
      border: 1px solid black;
      width: 40px;
      height: 40px;
      padding: 4px;
    }
    .greeting {
      flex-direction: column;
      margin: 0 19px;
      align-items: flex-start;
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      color: #015568;
      .name {
        font-size: 20px;
        font-weight: bold;
      }
    }
    hr {
      width: 147px;
      border: 1px solid #015568;
      margin: 4px 0 0 0;
    }
  }
  .user-menu-item-header {
    margin: 30px 0 10px 0;
    span {
      color: gray;
      font-size: 12pt;
      display: block;
    }
  }
  .item-button-container {
    justify-content: start !important;
    margin-bottom: 15px;
    width: 250px;
    a {
      text-decoration: none;
    }
    .item-icon {
      width: 35px;
      height: 35px;
    }
    .fa-item-icon {
      width: 35px;
      height: 35px;
      background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
      border-radius: 50px;
      svg {
        color: #f2f2f2;
      }
    }
  }

  .item-button-container-image {
    justify-content: start !important;
    margin-bottom: 15px;
    width: 250px;
    .rtl & {
      transform: translateX(5px);
    }
    .ltr & {
      transform: translateX(-5px);
    }
    .fa-item-icon {
      width: 35px;
      height: 35px;
      background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
      border-radius: 50px;
      svg {
        color: #f2f2f2;
      }
    }
    .item-icon-image {
      .ltr & {
        margin-right: -5px;
      }
      .rtl & {
        margin-left: -5px;
      }
      width: 45px;
      height: 45px;
    }
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 27px;
    height: 20px;
    top: 12px;
    .rtl & {
      right: 16px;
    }

    .ltr & {
      left: 16px;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    // background: #373a47;
    background: white;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    // background: #a90000;
    background: white;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    // background: #bdc3c7;
    // background: white;
    background: linear-gradient(180.63deg, #60df89 -29.47%, #00e8e1 124.33%);
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    // background: #373a47;
    // background: linear-gradient(180.63deg, #60df89 -29.47%, #00e8e1 124.33%);
    background: #e6e6e6;
    // padding: 2.5em 1.5em 0;
    padding: 1.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    // display: block;
    text-decoration: none;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 0 10px;
    color: #015568;
    &.active {
      color: blue;
    }
  }

  .menu-item-header {
    color: white;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .logout-item {
    margin: 50px 0 0 0;
    padding-bottom: 20px;
    .logout-container {
      justify-content: flex-start;
    }
  }
}

.ios-menu {
  display: flex;
  .rtl & {
    direction: rtl;
  }
  .menu-text-header {
    position: fixed;
    top: 8px;
    color: #60e4b3 !important;
  }
  .menu-header {
    display: flex !important;
    // padding: 0 0 20px 0 !important;
    margin: 30px 0 0 0 !important;
    .profile-image {
      border-radius: 50%;
      border: 1px solid black;
      width: 40px;
      height: 40px;
      padding: 4px;
    }
    .greeting {
      flex-direction: column;
      margin: 0 19px;
      align-items: flex-start;
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      color: #015568;
      .name {
        font-size: 20px;
        font-weight: bold;
      }
    }
    hr {
      width: 147px;
      border: 1px solid #015568;
      margin: 4px 0 0 0;
    }
  }
  .user-menu-item-header {
    margin: 30px 0 10px 0;
    span {
      color: gray;
      font-size: 12pt;
      display: block;
    }
  }
  .item-button-container {
    justify-content: start !important;
    margin-bottom: 15px;
    width: 250px;
    a {
      text-decoration: none;
      display: block;
    }
    .item-icon {
      width: 35px;
      height: 35px;
    }
    .fa-item-icon {
      width: 35px;
      height: 35px;
      background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
      border-radius: 50px;
      svg {
        color: #f2f2f2;
      }
    }
  }

  .item-button-container-image {
    justify-content: start !important;
    margin-bottom: 15px;
    width: 250px;
    .rtl & {
      transform: translateX(5px);
    }
    .ltr & {
      transform: translateX(-5px);
    }
    .fa-item-icon {
      width: 35px;
      height: 35px;
      background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
      border-radius: 50px;
      svg {
        color: #f2f2f2;
      }
    }
    .item-icon-image {
      .ltr & {
        margin-right: -5px;
      }
      .rtl & {
        margin-left: -5px;
      }
      width: 45px;
      height: 45px;
    }
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 27px;
    height: 20px;
    top: 40px;
    .rtl & {
      right: 16px;
    }

    .ltr & {
      left: 16px;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    // background: #373a47;
    background: white;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    // background: #a90000;
    background: white;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    // background: #bdc3c7;
    // background: white;
    background: linear-gradient(180.63deg, #60df89 -29.47%, #00e8e1 124.33%);
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    // background: #373a47;
    // background: linear-gradient(180.63deg, #60df89 -29.47%, #00e8e1 124.33%);
    background: #e6e6e6;
    // padding: 2.5em 1.5em 0;
    padding: 1.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    // display: block;
    text-decoration: none;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 0 10px;
    color: #015568;
    &.active {
      color: blue;
    }
  }

  .menu-item-header {
    color: white;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .logout-item {
    margin: 50px 0 0 0;
    padding-bottom: 20px;
    .logout-container {
      justify-content: flex-start;
    }
  }
}
