.create-inspiration-step-container {
  width: 100%;
  .background-image {
    height: 178px;
    background-image: url("../../../../../../assets/icons/person3.svg");
    background-repeat: no-repeat;
    background-position: 53px 8px;
  }
  .content {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 36px 20px 36px;
    .header {
      width: 231px;
      font-weight: 700;
      font-size: 16px;
      color: #015568;
      margin-bottom: 5px;
      .examples {
        font-size: 14px;
      }
    }
  }
  .footer-buttons {
    margin: 0 30px;
    justify-content: space-evenly !important;
    .create-inspiration-button {
      width: 30%;
    }
  }
}
