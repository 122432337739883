.select-category-conatiner {
  .description {
    margin-top: 60px;
    margin-bottom: 30px;
    color: #014352;
    margin: 30px 20px;
  }
  .displayed-categories-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .steps-dots-with-displayed-categories-button {
    margin-top: 15px !important;
  }
  .show-all-categories {
    justify-content: space-around;
    width: 147px;
    height: 49px;
    background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 53px;
    font-size: 35px;
    .plus-icon {
      height: 24px;
    }
  }
  .add-category-button {
    justify-content: space-around;
    width: 147px;
    height: 49px;
    background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 53px;
    font-size: 15px;
    .plus-icon {
      height: 24px;
      .rtl & {
        margin-right: 5px;
      }
      .ltr & {
        margin-left: 5px;
      }
    }
  }
}

@media (max-width: 320px) {
  .select-category-conatiner {
    .steps-dots-with-displayed-categories-button {
      margin-top: 5px !important;
    }
  }
}
