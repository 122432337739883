.search-modal-container {
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h5 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h6 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .advanced-container {
    display: flex;
  }
}
