.finished-setting-three-steps-popup {
  .headers {
    flex-direction: column;
    margin-top: 20px;
  }
  .speeach-bubble-text-container {
    flex-direction: column;
    margin-bottom: 10px;
    p {
      font-size: 14px !important;
      line-height: normal !important;
    }
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url("../../../../../../assets/icons/finished-setting-three-steps-popup-icon.svg");
    top: 105px;
    left: 60px;
    background-position: center;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
      no-repeat;
    //   background-size: 73px 95px, 102px 30px, 66px 20px, 66px 20px, auto, 74px 126px;
    width: 335px;
    height: 402px;
    z-index: 2;
  }

  .speech-bubble-container {
    z-index: -1;
  }
  h2.popup-title.small.flex {
    margin-top: 30px;
  }
  .speech-bubble-container {
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      line-height: 8px;

      text-align: center;

      color: #015568;
    }
  }
  .popup-bottom-bottons-container {
    padding: 15px 0 10px 0 !important;
    z-index: 3;
    .popup-button {
      width: 230px;
    }
  }
}
