.thats-ok-popup-container {
  margin: 30px;
  height: 403px;
  flex-direction: column;
  background-image: url("../../../../../../assets/icons/thats-ok.svg");
  background-repeat: no-repeat;
  background-position: 0px 160px;

  .speech-bubble-container {
    z-index: -1;
    p {
      font-style: normal;
      font-weight: 700;
    }
    .first-info {
      margin-top: 10px;
    }
  }
  .popup-bottom-bottons-container {
    left: 0;
    .popup-button {
      width: 230px;
    }
  }
}
