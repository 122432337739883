.view-boosters-popup-conatiner {
  margin: 0 30px 20px 30px;
  flex-direction: column;
  .headers {
    flex-direction: column;
    position: fixed;
    top: 55px;
    padding: 10px 0;
    width: 96.5%;
    background: linear-gradient(90deg, #ffffff 0%, #e5e5e5 85%);
  }
  .add-button {
    margin-top: 60px;
  }
  .continue-button {
    width: 100%;
  }

  .item-button-container {
    width: 250px;
    display: flex;
    justify-content: center;
    transform: translateY(10px);
    .item-icon {
      height: 80px;
      background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
      background-repeat: no-repeat;
      background-position: center;
      margin: 25px 0 30px 0;
      border-radius: 50px;
    }
  }
  .booster-card-container {
    margin-top: 15px;
    .media-center-icon-container {
      width: 110%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 10px;
      text-decoration: none;
      .media-center-icon {
        width: 50px;
        height: 50px;
        vertical-align: middle;
      }
      .media-center-link-text {
        color: #015568;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        width: 80%;
      }
    }
    .booster-card {
      width: 265px;
      height: max-content;
      background: #d9d9d9;
      border-radius: 22px;
      margin: 5px 0;
      .flex {
        justify-content: space-between;
        padding: 0 15px;
        word-wrap: break-word;
        // display: inherit;
        .fa-trash-can.trash-icon {
          color: #379657;
          .rtl & {
            margin-right: 10px;
          }
          .ltr & {
            margin-left: 10px;
          }
        }
        .remove-booster-content {
          display: block;
          width: 90%;
          padding: 20px 0;
          word-break: break-word !important;
          table {
            word-break: break-word !important;
            td {
              color: #015568;
              h4 {
                margin: 0;
                padding: 0;
                display: inline;
              }
            }
          }
        }
      }
    }
    // .bag-boosters-icon {
    //   height: 80px;
    //   background-image: url("../../../../assets/icons/bag-booster.svg");
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   margin: 25px 0 30px 0;
    // }
  }
}
