.welcome-container {
  margin-top: 45px;
  flex-direction: column;
  .description {
    width: 329px;
    line-height: 17px;
    text-align: center;
    color: #015568;
    margin-bottom: 10px;
  }
  .moto {
    width: 240px;
    text-align: center;
    color: #015568;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .welcome-icon {
    margin-bottom: 44px;
  }
}

@media (max-width: 320px) {
  .welcome-container {
    margin-top: 20px;
  }
}
