.profile-image-upload-old-conatiner {
  .user-image {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px black solid;
  }
  .profile-image-popup {
    flex-direction: column;
    .image-upload-button-container {
      display: flex;
      justify-content: space-evenly !important;
      width: 100%;
      button {
        width: 120px;
      }
    }
    .hidden-file-input {
      display: none;
    }
  }
}
