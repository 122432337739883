.step-dots-container {
  bottom: -40px;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 10px;
  z-index: 2;

  .step-dot {
    height: 10px;
    width: 10px;
    border-radius: 30px;
    margin: 0px 3.5px;
    background: linear-gradient(90deg, #e6e6e6 0%, #f2f2f2 60.98%);
    box-shadow: 1.5px 1.7px 4px rgba(0, 0, 0, 0.2);
    transform: matrix(1, 0, 0, -1, 0, 0);
    &.full {
      height: 20px;
      width: 20px;
      background: linear-gradient(90deg, #60df89 18%, #00e8e1 85%);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }
  .button {
    margin: 0 10px;
  }
}
