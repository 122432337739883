.report-card-container {
  width: 300px;
  min-height: 50px;
  height: max-content;
  background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 53px;
  margin: 0 5px 20px 5px;
  padding: 10px;
  color: #014352;
  cursor: pointer;
  border: 2px solid;
  text-decoration: none !important;

  &:hover {
    opacity: 0.7;
  }
  h2 {
    margin-top: 5px;
    padding: 0 5px !important;
  }
  p {
    padding: 0;
  }

  .title {
    font-size: 22px;
    white-space: initial;
    text-align: center;
  }
}

@media only screen and (max-width: 1000px) {
  .report-card-container {
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 40%;
    text-decoration: none !important;
    .title {
      font-size: 16px !important;
    }
    .small-title {
      font-size: 12px !important;
    }
  }
}
