.image-upload-container {
  align-items: center;
  .header-buttons-container {
    justify-content: space-evenly;
    .font-button {
      border: 1px solid #d9d9d9 !important;
      background: linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%);
      height: 37px;
      border-radius: 30px;
      width: 100px;
    }
  }
  .hidden-file-input {
    display: none;
  }
  .selected-image {
    width: 250px;
    height: 250px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px black solid;
  }
  .choose-image {
    height: 47px;
    background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
    border-radius: 30px;
    width: 100%;
    .text {
      width: 163px;
      font-weight: 700;
      font-size: 20px;
      color: #014352;
      .rtl & {
        margin-right: 10px;
      }
      .ltr & {
        margin-left: 10px;
      }
    }
  }
  .avatar-container {
    flex-direction: column;
  }
  .image-upload-buttons {
    margin: 10px 0 !important;
    justify-content: space-around !important;
    .popup-button {
      width: 120px;
    }
  }
}
