.pathways-schedule-container {
  margin-top: 40px;
  color: #015568;
  h3 {
    text-align: center;
    margin: 5px 0;
    font-size: 20px;
  }
  p {
    margin: 5px 0 0 0;
    padding: 0;
  }
  .reminder-container {
    width: 300px;
    margin: 10px auto;
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      border-radius: 10px;
      // box-shadow: 0 10px 10px rgb(0 0 0 / 20%);
      background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

      max-width: 100%;
      overflow: hidden;
      padding: 10px;
      h4 {
        font-weight: bold;
        margin: 0;
      }
      .break-words {
        min-width: 90%;
        .description {
          font-size: 16px;
        }
      }
      .icon {
        margin: auto;
        height: 24px;
      }
    }
  }
}
