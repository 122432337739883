.my-health-container {
  margin-top: 20px;
  text-align: center;
  h1 {
    text-align: center;
  }

  h4 {
    margin: 0;
    padding: 0;
  }
}
