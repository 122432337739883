.ask-for-more-container {
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h5 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h6 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .before-send {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .looking-image-container {
      margin-top: 20px;
      .looking-image {
        width: 250px;
        height: 225px;
      }
    }

    .looking-text {
      color: #0d4253;
      font-size: 22.5px;
      width: 70%;
    }

    .description-text {
      color: #0d4253;
      font-size: 22.5px;
      width: 70%;
      margin-top: 10px;
    }

    .textarea-container {
      margin-top: 20px;
      .looking-textarea {
        width: 80vw;
        height: 19vh;
        border-radius: 20px;
        border-color: #0d4253;
        resize: none;
        font-size: 20px;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .send-container {
      display: flex;
      justify-content: center;
      button {
        width: 100px;
      }
    }
  }

  .after-send {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .thanks-image-container {
      margin-top: 20px;
      .thanks-image {
        width: 350px;
        height: 225px;
      }
    }

    .thanks-text-first {
      color: #0d4253;
      margin-top: 20px;
      font-size: 35px;
    }

    .thanks-text-second {
      color: #0d4253;
      font-size: 20px;
      width: 80%;
    }

    .send-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      button {
        width: 200px;
      }
    }
  }
}
