.animate-map-container {
  .map-grad-layer {
    width: 100%;
    height: 100%;
    z-index: 3999;
    position: absolute;
    opacity: 0.5;
    background: linear-gradient(
      41deg,
      rgba(61, 192, 94, 1) 0%,
      rgba(255, 207, 200, 0.4150253851540616) 35%,
      rgba(56, 57, 207, 0.1909357492997199) 70%
    );
  }
  .step1-banner {
  }
  .step2-banner {
  }
  .step3-banner {
  }
  .transparent1 {
    width: 40%;
    height: 7%;
    position: absolute;
    top: 71%;
    left: 17%;
    cursor: "pointer";
    //background-color: red;
    z-index: 4000;
  }
  .transparent2 {
    width: 40%;
    height: 7%;
    position: absolute;
    top: 53%;
    left: 35%;
    //background-color: red;
    z-index: 4001;
    cursor: "pointer";
  }
  .transparent3 {
    cursor: "pointer";
    width: 40%;
    height: 6%;
    position: absolute;
    top: 41%;
    left: 49%;
    //background-color: red;
    z-index: 4002;
  }
}
