.quote-container {
  margin: 30px 10px;
  border-radius: 5px;
  max-width: 700px;
  padding: 20px;
  .quote,
  .quote q {
    color: hsl(185, 83%, 25%);
    font-size: 1.1rem;
    font-weight: 600;
    font-style: italic;
    line-height: 1.48;
    quotes: none;
    &:before,
    &:after {
      content: none;
    }
  }
  .source {
    color: hsl(185, 83%, 25%);
    font-style: italic;
    line-height: 1.3;
    margin-top: 5%;
  }
}
