.footer-menu-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  .menu-drawer-button {
    position: absolute;
    z-index: 1000;
    &.close {
      top: -25px;
      width: 25px;
      height: 22px;
      background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      border-radius: 53px;
      .rtl & {
        left: 2px;
      }
      .ltr & {
        right: 2px;
      }
      .icon {
        height: 15px;
      }
    }
    &.open {
      bottom: 5px;
      width: 50px;
      height: 30px;
      background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      border-radius: 53px;
      animation: bounce 4s infinite;
    }
  }
  .animated-menu-container {
    height: 90px;
    width: 100%;
    z-index: 200;
    bottom: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    box-shadow: 0px 4px 4px rgba(111, 111, 111, 0.2);
    border-radius: 80px 80px 0 0;
    .buttons-container {
      margin-top: -20px;
      .icon {
        height: 30px;
        // &.boosters {
        //   height: 55px;
        //   margin-top: -15px;
        // }
        &.fa {
          color: #82da90;
        }
      }
    }
  }
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.fill {
  flex: 1;
  background: transparent;
}

.drawer {
  height: 100%;
  // background-color: blue;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@media only screen and (max-width: 320px) {
  .footer-menu-container {
    .animated-menu-container {
      .buttons-container {
        .icon {
          height: 20px;
          &.boosters {
            height: 55px;
            margin-top: -15px;
          }
          &.fa {
            color: #82da90;
          }
        }
      }
    }
  }
}
