.tip-container {
  min-height: 54px;
  background: #d9d9d9;
  border-radius: 37.5px;
  justify-content: flex-start !important;
  .text {
    width: 163px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 14px;
    color: #015568;
    &.all-line {
      width: 100% !important;
      padding: 10px;
      line-height: 18px;
    }
  }
  svg {
    .rtl & {
      margin-right: 5px;
      margin-left: 10px;
    }

    .ltr & {
      margin-left: 5px;
      margin-right: 10px;
    }
  }
}
