.speech-bubble-container {
  position: absolute;
  height: 60%;
  background-repeat: no-repeat, no-repeat;
  background-position: 6px 6px, 0 0;
  background-color: transparent;

  .content {
    flex-direction: column;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #015568;
    p {
      margin: 0;
      padding: 0;
    }
  }
}
