.finished-first-step-popup-container {
  margin-top: 30px;
  height: 403px;
  flex-direction: column;
  background-image: url("../../../../../../assets/icons/finished-first-step-popup.svg");
  background-repeat: no-repeat;
  background-position: 30px 58px;

  .speech-bubble-container {
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
    }
  }
  .popup-bottom-bottons-container {
    .popup-button {
      width: 230px;
    }
  }
}
