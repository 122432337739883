.IframeContentFullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6000;
  //   background-color: white;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .rtl {
    left: 10px;
    right: unset;
  }
}
