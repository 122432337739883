.title-container {
  margin-top: 0 !important;
  margin: 0 auto;
  font-size: 16px;

  .booster-img {
    position: absolute;
    width: 40px;
    height: 40px;
    .rtl & {
      transform: translateX(120px);
    }
    .ltr & {
      transform: translateX(-120px);
    }
  }

  .title {
    .title-text {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      gap: 0px;
    }
    width: 315px;
    height: 50px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: #014352;
    background: linear-gradient(0deg, #f4f4f4 0%, #ffffff 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 53px;
    font-size: 20px;
    text-align: center;
    .small {
      font-size: 18px;
      font-weight: normal;
      width: 100%;
    }
    .big {
      width: 100%;
    }
  }
}

@media (max-width: 320px) {
  .title-container {
    width: 220px;
    font-size: 12px;
  }
}
