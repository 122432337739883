.a-bit-stuck-container {
  margin: 0 30px 20px 30px;
  flex-direction: column;
  .headers {
    flex-direction: column;
    margin: 20px 0 10px 0;
  }
  .thats-ok {
    width: 250px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #015568;
    margin: 10px 0 0 0;
  }
  .footer-button-container {
    position: relative;
    .footer-button {
      font-size: 13px;
      text-align: center;
      margin-right: 10px;
    }
  }
}
