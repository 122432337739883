.welcome-back-stats-card-container {
  flex-direction: row;
  width: 250px;
  justify-content: space-between !important;
  margin: 10px 0;
  font-weight: bold;
  font-size: 16px;
  .header-text-and-icon {
    .text {
      .rtl & {
        margin-right: 10px;
      }
      .ltr & {
        margin-left: 10px;
      }
    }

    .image-icon {
      width: 20px;
      height: 20px;
      .ltr & {
        margin-left: -1px;
      }
      .rtl & {
        margin-right: -1px;
      }
    }
  }
  .value-text {
    font-size: 24px;
  }
}
