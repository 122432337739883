.remove-map-popup-container {
  margin: 20px 10px;
  text-align: center;
  color: #015568;
  .goal {
    font-style: italic;
  }
  .note-container {
    margin-bottom: 20px;
    p {
      margin: 5px 0;
    }
    .header {
      font-weight: bold;
    }
    .description {
      font-weight: bold;
      text-align: center;
    }
  }
  .flex {
    justify-content: space-evenly !important;
    .popup-button {
      width: 40%;
    }
  }
}
