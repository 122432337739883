.content-box-container {
  .vimeo-frame {
    & > iframe {
      width: 100%;
      min-height: 80vh;
    }
  }
  .view-video-title {
    padding-bottom: 10px;
  }
  display: flex;
  flex: 25%;
  background: linear-gradient(90deg, #f9f9f9 0%, #e5e5e5 85%);
  margin: 20px;
  padding: 20px;
  align-items: center;
  height: 175px;
  width: 375px;

  .info-container {
    width: 65%;
    word-wrap: break-word;
    .content-link {
      width: 100%;

      &:hover {
        cursor: pointer;
      }
      display: inline-block;
    }
  }

  .drag-handle {
    width: 25px;
    height: 25px;
    background-color: black;
    &:hover {
      cursor: pointer;
    }
  }
  .actions-container {
    padding: 5px;
    .edit-container {
      padding-bottom: 10px;
      .edit {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .duplicate-container {
      padding-bottom: 10px;
      .duplicate {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .delete-container {
      .delete {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .selected-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 4px;
  }
}

@media (max-width: 768px) {
  .content-box-container {
    margin: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0px;
    height: 250px;
    width: 350px;
  }
}
