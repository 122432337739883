.HopeJourneyGuidePopup {
  max-height: 70dvh;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 20px;
  align-items: center;
  .close_btn {
    margin-left: auto;
  }
  .text_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #015568;
    font-size: 20px;
    p {
      margin: 0;
    }
  }

  .actor_image {
    width: 40%;
  }
  .popup-button {
    width: 70%;
  }
}
