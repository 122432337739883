.good-to-have-you-back-container {
  flex-direction: column;
  height: 300px;
  background-image: url("../../../../assets/images/person.png");
  background-repeat: no-repeat;
  background-position: 47px 51px;
  .header-container {
    margin-top: 33px;
    flex-direction: column;
  }
  .content {
    // flex-direction: column;
    position: relative;
    top: 250px;
    margin: 0 36px;
    .header {
      font-size: 14px;
      line-height: 14px;
      color: #015568;
      margin: 5px 0 10px 0;
      &.center {
        text-align: center;
      }
    }
  }
  .footer-buttons-container {
    justify-content: space-between !important;
  }
}
