.newsletter-popup-container {
  flex-direction: column;
  margin: 20px;
  p {
    margin: 20px 0 !important;
    text-align: center;
    color: #015568;
    font-weight: bold;
  }
  .footer-buttons-container {
    justify-content: space-between !important;
    .newsletter-button {
      font-size: 14px;
      width: 130px;
    }
  }
}
