.edit-inspiration-step-container {
  background-image: url("../../../../../../assets/icons/bag-booster.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: 48px;
  .content {
    flex-direction: column;
    align-items: flex-start;
    margin: 50px 36px 20px 36px;
    .header {
      width: 211px;
      font-weight: 700;
      font-size: 14px;
      color: #015568;
      margin-bottom: 5px;
      .examples {
        font-size: 12px;
      }
    }
    .do-you-want-to-add {
      width: 223px;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      color: #015568;
      margin: 16px 0 10px 0;
    }
    .edit-inspiration-button {
      width: 45%;
    }
  }
}
