.draft-map-popup-container {
  padding: 0 10px;
  text-align: center;
  .popup-title-container {
    margin-top: 30px;
  }
  .popup-title-container .popup-title.big {
    line-height: 1;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .footer-buttons-container {
    .footer-buttons {
      font-size: 14px;
      margin: 10px;
      text-align: center !important;
    }
  }
}
