.edit-need-step-container {
  .background-image {
    height: 241px;
    background-image: url("../../../../../../assets/images/person.png");
    background-repeat: no-repeat;
    background-position: 48px 4px;
  }
  .content {
    margin: 0 36px;
    .question {
      width: 203px;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #015568;
      .rtl & {
        margin: 15px 0 5px 15px;
      }
      .ltr & {
        margin: 15px 15px 5px 0;
      }
    }
    .header {
      width: 211px;
      font-weight: 700;
      font-size: 16px;
      color: #015568;
      margin-bottom: 5px;
    }
    .edit-need-button {
      width: 100%;
    }
  }
}
