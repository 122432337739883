.messages-container {
  text-align: center;
  h1 {
    text-align: center;
  }

  h4 {
    margin: 0;
    padding: 0;
  }

  /* Chat */

  .chat {
    height: calc(100% - 69px);
  }

  .chat-container {
    height: 100%;
  }

  /* Conversation */

  // .conversation {
  //   height: calc(100% - 12px);
  //   position: relative;
  //   background: #efe7dd
  //     url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg")
  //     repeat;
  //   z-index: 0;
  // }

  .conversation ::-webkit-scrollbar {
    transition: all 0.5s;
    width: 5px;
    height: 1px;
    z-index: 10;
  }

  .conversation ::-webkit-scrollbar-track {
    background: transparent;
  }

  .conversation ::-webkit-scrollbar-thumb {
    background: #b3ada7;
  }

  .conversation .conversation-container {
    height: 100%;
    // box-shadow: inset 0 10px 10px -10px #000000;
    overflow-x: hidden;
    padding: 0 16px;
    margin-bottom: 5px;

    .message-title {
      font-size: 14px;
      font-weight: 700;
      color: #015568;
      margin: 0 0 10px 0;
      .ltr & {
        text-align: left;
      }
      .rtl & {
        text-align: right;
      }
    }

    .message-body {
      float: left !important;
      font-size: 14px;
      line-height: 15px;
      color: #015568;
      p {
        font-size: 14px;
      }
      .ltr & {
        text-align: left !important;
      }
      .rtl & {
        text-align: right !important;
      }
    }
  }

  .conversation .conversation-container:after {
    content: "";
    display: table;
    clear: both;
  }

  /* Messages */

  .message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 15px;
    padding: 8px;
    position: relative;
    margin: 8px 0;
    max-width: 85%;
    word-wrap: break-word;
    // z-index: -1;
  }

  .message:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
  }

  .metadata {
    display: inline-block;
    .ltr & {
      float: right;
    }
    .rtl & {
      float: left;
    }
    .ltr & {
      padding: 0 0 0 7px;
    }
    .rtl & {
      padding: 0 7px 0 0;
    }
    position: relative;
    bottom: -4px;
  }

  .metadata .time {
    color: rgba(0, 0, 0, 0.45);
    font-size: 11px;
    display: inline-block;
  }

  .metadata .tick {
    display: inline-block;
    margin-left: 2px;
    position: relative;
    top: 4px;
    height: 16px;
    width: 16px;
  }

  .metadata .tick svg {
    position: absolute;
    transition: 0.5s ease-in-out;
  }

  .metadata .tick svg:first-child {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: perspective(800px) rotateY(180deg);
    transform: perspective(800px) rotateY(180deg);
  }

  .metadata .tick svg:last-child {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: perspective(800px) rotateY(0deg);
    transform: perspective(800px) rotateY(0deg);
  }

  .metadata .tick-animation svg:first-child {
    -webkit-transform: perspective(800px) rotateY(0);
    transform: perspective(800px) rotateY(0);
  }

  .metadata .tick-animation svg:last-child {
    -webkit-transform: perspective(800px) rotateY(-179.9deg);
    transform: perspective(800px) rotateY(-179.9deg);
  }

  .message:first-child {
    margin: 16px 0 8px;
  }

  .message.received {
    // background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    background-color: #f5f5f5;
    border-radius: 0px 5px 5px 5px;
    .ltr & {
      float: left;
    }
    .rtl & {
      float: right;
    }
  }

  .message.received .metadata {
    .ltr & {
      padding: 0 0 0 16px;
    }
    .rtl & {
      padding: 0 16px 0 0;
    }
  }

  .message.received:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #f5f5f5 transparent transparent;
    top: 0;
    left: -10px;
  }

  .message.sent {
    // background: #e1ffc7;
    // background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    background-color: #f5f5f5;
    border-radius: 5px 0px 5px 5px;
    .ltr & {
      float: right;
    }
    .rtl & {
      float: left;
    }
  }

  .message.sent:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #f5f5f5;
    top: 0;
    right: -10px;
  }

  /* Small Screens */

  @media (max-width: 768px) {
    .screen-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    // .conversation {
    //   height: calc(100vh - 200px);
    // }
    // .conversation .conversation-container {
    //   height: 100%;
    // }
  }
}
