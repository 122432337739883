.create-country-container {
  display: flex;
  .popup-children::-webkit-scrollbar {
    display: none;
  }
  .create-input {
    display: flex;
    justify-content: center;
  }
  .create-country-title {
    padding-bottom: 20px;
  }
  .content-country-name-container {
    padding-bottom: 10px;
  }
  .font-button {
    margin: 10px 10px 10px 10px;
    border: 1px solid #d9d9d9 !important;
    background: linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%);
    height: 37px;
    border-radius: 30px;
    width: 100px;
  }
  .save-container {
    position: absolute;
    bottom: 0;
    .rtl & {
      right: 40%;
    }
    .ltr & {
      left: 40%;
    }
    padding-bottom: 20px;
  }
  .error-container {
    display: flex;
    justify-content: center;
    color: red;
  }
}
