.finished-setting-goal-popup-container {
  height: 100%;
  .background-image {
    position: relative;
    z-index: 2000;
    height: 275px;
    background-image: url("../../../../../../assets/icons/set-pathway.svg");
    background-repeat: no-repeat;
    background-position: 22px 75px;
  }
  .speech-bubble-container {
    height: 40%;
  }
}
