.remove-notifications-container {
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #ffffff 0%, #e5e5e5 85%);
  // text-align: center;
  padding-top: 100px;
  p {
    margin: 0 30px;
  }
  .checkboxes-container {
    flex-direction: column;
    margin: 30px;
  }
  .save-button {
    width: 80%;
    margin: auto;
  }
}
