.finshed-creating-map-popup-container {
  margin: 0 10px;
  justify-content: start !important;
  .step-container {
    background: #d9d9d9;
    border-radius: 22px;
    padding: 10px;
    font-weight: 700;
    text-align: center;
    color: #015568;
    margin-top: 0 !important;
  }
  .buttons-container {
    width: 100%;
    margin-bottom: 10px;
    .button {
      width: 120px;
    }
  }
}

@media (max-width: 320px) {
  .finshed-creating-map-popup-container {
    height: 390px !important;
    p {
      font-size: 12px;
    }
    svg {
      height: 100px;
    }
  }
}
