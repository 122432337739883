.admin-dashboard-container {
  color: #014352;
  width: 100%;
  margin: 120px auto 0 auto;
  background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
  border-radius: 10px;

  h2 {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .statistics-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
    .common-maps-container {
      text-align: center;
      margin-top: 10px;
      .common-maps-title {
        margin-bottom: 5px;
      }
      p {
        margin: 0;
      }
      .category {
        color: red;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
  hr {
    margin-bottom: 50px;
  }
}
.app-header {
  border-radius: initial;
  .user-name {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  .admin-dashboard-container {
    width: 100%;
    .statistics-container {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .app-header {
    border-radius: 0 0 50% 50%;
    .user-name {
      font-size: 14px;
    }
  }
}
