.delete-country-container {
  .delete-country-title {
    padding-bottom: 20px;
  }

  .delete-country-info {
    display: flex;
    justify-content: center;
  }

  .save-container {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    .action-button {
      margin: 2px;
    }
  }
}
