.galery-popup-container {
  //display: none;
  display: block;
  left: 0px;
  top: 0px;
  position: fixed;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 10000;
  &.active {
    display: block;
  }
  .gallery-popup-buttons {
    width: 100%;
    position: fixed;
    top: 0px;
    background-color: black;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0;
  }
  .selected-image-in-gallery {
    border: 3px #40e2a9 solid;
    box-sizing: border-box !important;
  }
}
