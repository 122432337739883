.view-content-tip-container {
  position: relative;
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h5 {
    font-size: 2vh;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h6 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .info-container {
    margin-top: 20px;
    .ltr & {
      margin-left: 20px;
    }
    .rtl & {
      margin-right: 20px;
    }
    .info-container-inner {
      height: 325px;
      overflow-y: auto;
      overflow-x: hidden;
      .content-date {
        color: rgb(182, 182, 182);
        font-size: 2vh;
      }
      .content-title {
        color: #0d4253;
        font-size: 2.75vh;
      }
      .content-desription {
        color: #0d4253;
        width: 225px;
        word-wrap: break-word;
      }
      .read-more-container {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    .actions-container {
      position: absolute;
      bottom: 20px;
      display: flex;
      .booster-icon {
        margin-top: 10px;
        width: 35%;
      }

      .share-icon {
        margin-top: 10px;
        .ltr & {
          margin-left: 10px;
        }
        .rtl & {
          margin-right: 10px;
        }
        width: 30%;
      }
    }
    .info-container-inner::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    .info-container-inner::-webkit-scrollbar:vertical {
      width: 12px !important;
    }

    .info-container-inner::-webkit-scrollbar:horizontal {
      height: 12px !important;
    }

    .info-container-inner::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      border: 2px solid #ffffff;
    }

    .info-container-inner::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }
  }
}
