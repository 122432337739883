.edit-content-container {
  display: flex;
  .popup-children::-webkit-scrollbar {
    display: none;
  }
  .content-info {
    .create-content-title {
      padding-bottom: 10px;
    }
    .create-input {
      display: flex;
      justify-content: center;
    }

    .select-content-type-container {
      padding-top: 10px;
    }
    .content-description-container {
      .content-description {
        height: 200%;
      }
    }
  }
  .avatar-container {
    flex-direction: column;
    justify-content: center;
    padding-bottom: 50px;
  }

  .font-button {
    margin: 10px 10px 10px 10px;
    border: 1px solid #d9d9d9 !important;
    background: linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%);
    height: 37px;
    border-radius: 30px;
    width: 100px;
  }

  .hidden-file-input {
    display: none;
  }
  .save-container {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  .error-container {
    display: flex;
    justify-content: center;
    color: red;
    margin-top: -10px;
    margin-bottom: 15px;
  }
}
