.clicked-obstacle-popup-container {
  height: 100%;
  flex-direction: column;
  margin: 30px;
  .headers {
    flex-direction: column;
    text-align: center;
    .obstacle-icon {
      margin-top: 10px;
    }
  }
  input {
    width: 20px;
  }
  .got-it-button {
    width: 80%;
    margin: 20px 0;
  }
}
