.badges-popup-container {
  .header-container {
    flex-direction: column;
    top: 0;
    padding: 10px 0;
    width: 100%;
    margin-top: 20px;
    gap: 10px;
    .status-image {
      width: 15px;
      height: 15px;
    }
  }
  .upper-text {
    width: 80%;
    .ltr & {
      margin-left: 10%;
    }
    .rtl & {
      margin-right: 10%;
    }
  }

  .badges-info-container {
    width: 80%;
    .ltr & {
      margin-left: 10%;
    }
    .rtl & {
      margin-right: 10%;
    }
    .badges-info {
      display: flex;
      gap: 6.66%;
      margin-top: 10px;
      h5 {
        margin: 0;
        padding: 0;
        line-height: 5px;
      }
      h6 {
        margin: 0;
        padding: 0;
        line-height: 5px;
        font-size: 8px;
      }
      .status-icon-container {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        gap: 10px;
        .icon-container {
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
    }
  }

  .explanation-text {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    .ltr & {
      margin-left: 10%;
    }
    .rtl & {
      margin-right: 10%;
    }
    h4 {
      margin: 0;
    }
    .popup-title-container {
      margin-bottom: 10px;
    }
  }
}
