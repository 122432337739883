.step-details-popup-container {
  flex-direction: column;
  margin: 20px;
  .fields {
    align-items: flex-start !important;
    margin-top: 15px;
    .input-container {
      width: 100%;
    }
  }
  .buttons {
    width: 100%;
    justify-content: space-evenly;
    button {
      width: 90px;
      height: 55px;
      border-radius: 60px;
    }
  }

  .step-info {
    color: #555;

    width: 300px;
    background: linear-gradient(187.55deg, #fefdfd -15.08%, #eeeaea 58.2%);
    box-shadow: 0px 4px 4px rgba(111, 111, 111, 0.2);
    border-radius: 30px;
    min-height: 30px;
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    margin-bottom: 20px;
    span {
      width: 280px;
      word-wrap: break-word;

      padding-top: 7.5px;
      .rtl & {
        padding-right: 10px;
      }
      .ltr & {
        padding-left: 10px;
      }
    }
  }

  .goal-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }

  @media (max-width: 320px) {
    .step-details-popup-container {
      max-height: 400px;
    }
  }
}
