.user-content-box-container {
  background: white;
  height: 400px;
  width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 18px 0 20px -15px rgba(184, 180, 180, 0.75),
    -18px 0 20px -15px rgba(184, 180, 180, 0.75),
    0 18px 20px -15px rgba(184, 180, 180, 0.75);
  border-radius: 5px 5px 5px 5px;
  position: relative;

  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h5 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 18px;
  }

  h6 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 13px;
  }

  .media-type-icon {
    height: 50%;
    width: 50%;
    .rtl & {
      transform: translate(-12px, 10px);
    }
    .ltr & {
      transform: translate(12px, 10px);
    }
  }

  .media-type-icon-no-image {
    height: 30%;
    width: 30%;
    .rtl & {
      transform: translate(-105px, 60px);
    }
    .ltr & {
      transform: translate(95px, 60px);
    }
  }

  .media-type-icon-no-image-long {
    height: 10%;
    width: 15%;
    .rtl & {
      transform: translate(-130px, 60px);
    }
    .ltr & {
      transform: translate(120px, 60px);
    }
  }

  .video-icon-container {
    .video-type-icon {
      width: 65%;
    }
    position: absolute;
    .rtl & {
      transform: translate(-105px, 60px);
    }
    .ltr & {
      transform: translate(95px, 60px);
    }
  }

  .info-container {
    word-wrap: break-word;
    margin-top: 10px;
    width: 90%;
    .rtl & {
      transform: translateX(-30px);
    }
    .ltr & {
      transform: translateX(30px);
    }

    .content-link {
      width: 100%;

      &:hover {
        cursor: pointer;
      }
      display: inline-block;
    }
  }

  .actions-container {
    padding: 10px;
    display: flex;
    position: absolute;
    bottom: 5px;
    gap: 10px;
    .rtl & {
      transform: translateX(-20px);
    }
    .ltr & {
      transform: translateX(20px);
    }
    .bookmark-icon {
      &:hover {
        cursor: pointer;
      }
    }
    .media-icon {
      height: 30px;
      width: 30px;
    }
  }

  .content-date {
    color: rgb(182, 182, 182);
    font-size: 2vh;
    // transform: translateY(100px);
  }
  .content-title {
    font-size: 3.5vh;
    width: 85%;
  }
  .content-desription {
    width: 85%;
    word-wrap: break-word;
  }

  .image-container {
    .type-icon {
      position: absolute;
      height: 60px;
      background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
      width: 50px;
      border-radius: 0px 0px 50px 50px;
      .ltr & {
        transform: translateX(220px);
      }
      .rtl & {
        transform: translateX(-220px);
      }
    }
    .selected-image {
      width: 300px;
      height: 225px;
      border-radius: 5px 5px 0px 0px;
    }
  }
  .type-no-image {
    background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
    width: 300px;
    height: 225px;
    border-radius: 5px 5px 0px 0px;
  }
}

.vimeo-frame {
  & > iframe {
    width: 100%;
    min-height: 80vh;
  }
}
.view-video-title {
  padding-bottom: 10px;
}
