.define-step-popup-container {
  height: 100%;
  flex-direction: column;
  margin: 20px 30px 0 30px;
  justify-content: flex-start !important;
  .popup-titles-wrapper {
    margin: 0 0 20px 0;
  }
  .description2 {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: #015568;
    margin-bottom: 20px;
    text-align: center;
  }
}
.date-time-container {
  // width: 136px !important;
}
.description1 {
  width: 241px !important;
  line-height: 18px !important;
  margin-bottom: 20px;
  text-align: center !important;
  p {
    font-weight: 700;
    font-size: 15px;
    line-height: 15px;
    color: #015568;
  }
}
.left,
.right {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
input {
  width: 230px;
}
