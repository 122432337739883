.create-steps-container {
  height: 80vh;
  padding: 0 3px;
  .description {
    margin: 10px 0 20px 0;
    margin: auto;
    width: 90%;
  }
  .tabs-container {
    margin-bottom: 70px;
    .react-tabs__tab-list {
      border-bottom: none;
      display: flex;
    }
    .react-tabs__tab {
      color: #085267;
      font-weight: bold;
      height: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
      margin: 0 1px;
      line-height: 30px;
      flex: 1 0 25%;
      .rtl & {
        font-size: small;
      }
      &:nth-child(1) {
        .rtl & {
          border-radius: 0 50px 50px 0;
        }
        .ltr & {
          border-radius: 50px 0 0 50px;
        }
      }
      &:nth-child(3) {
        .rtl & {
          border-radius: 50px 0 0 50px;
        }
        .ltr & {
          border-radius: 0 50px 50px 0;
        }
      }
    }
    .react-tabs__tab--selected {
      background: linear-gradient(89.93deg, #00e37f 4.49%, #00ebe1 83.69%);
    }
  }
  .goal-buttons {
    width: 100%;
    padding-bottom: 20px;
  }
}
