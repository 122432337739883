.popup-title-container {
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .popup-title {
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color: #015568;
    width: 100%;
    margin: 0;
    &.small {
      font-size: 20px;
    }
    &.big {
      font-size: 30px;
    }
  }
  .popup-title.small {
    margin: auto;
    text-align: left;
    .ltr & {
      text-align: left;
    }
    .rtl & {
      text-align: right;
    }
  }
}
