.date-input {
  justify-content: flex-start !important;
  width: 100% !important;
  .date-time-container {
    width: 100% !important;
    background: linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%);
    min-height: 37px;
    max-height: max-content;
    // width: 150px;
    border-radius: 30px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .active {
      display: none;
    }
    .react-datepicker-wrapper {
      display: none;
    }
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 22px;
      padding: 0 5px;
      height: 100%;
    }
    .calendar-icon {
      padding: 0 5px;
    }
    .v-icon {
      padding: 0 5px;
    }
  }
  .trash-icon {
    .ltr & {
      left: 5px;
    }
    .rtl & {
      right: 5px;
    }

    position: relative;
  }
  .react-datepicker-popper {
    position: fixed !important;
    transform: translate3d(5vw, 198px, 0px) !important;
  }
  .active {
    outline: none;
    border: 2px solid red;
    box-shadow: 0 0 10px #719ece;
    border-radius: 30px;
  }
}

@media (max-width: 320px) {
  .date-input {
    .react-datepicker-popper {
      display: flex;
      transform: translate3d(0, 198px, 0px) !important;
    }
  }
}
