.login-container {
  height: 100%;
  .sign-in-title {
    text-align: center;
    font-size: 30px;
    display: block;
    margin-block-start: 0 !important;
    margin-block-end: 10px !important;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: #e75417;
  }
  &.phone {
    .select-lang-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .select-lang-info {
        width: 20%;
      }

      .lang-select {
        padding-top: 20px;
      }
    }
    .top-icon {
      background-image: url("../../assets/icons/hopetimize.svg");
      background-position: center;
      background-repeat: no-repeat;
      height: 100px;
    }
    .background-image {
      background-image: url("../../assets/icons/login.svg");
      background-position: center;
      background-repeat: no-repeat;
      height: 350px;
    }
    .content {
      flex-direction: column;
      height: fit-content;
      // top: 65%;
      position: relative;
      .phone-icon-and-text-container {
        width: 80%;
        justify-content: center;
        margin-bottom: 8px;
        .your-mobile-phone-text {
          margin: 0 14px;
          font-size: 20px;
          color: #015568;
          // width: 200px;
          &.eg {
            font-size: 12px;
          }
        }
      }
      .PhoneInput {
        width: 30%;
      }
      .PhoneInputCountry {
        width: 81px;
        height: 37px;
        background: #d9d9d9;
        border-radius: 18.5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .PhoneInputInput {
        width: 155px;
        height: 37px;
        background: #d9d9d9;
        border-radius: 18.5px;
        border: none;
        font-size: 16px;
        padding: 0 10px;
      }
      .we-will-send-you {
        width: 500px;
        text-align: center;

        font-size: 16px;
        color: #4c5f71;
        margin-top: 11px;
        margin-bottom: 20px;
      }
      .error-message {
        margin-top: 10px;
      }
    }
    .not-register {
      display: flex;
      gap: 5px;
      line-height: 10px;
      margin-bottom: 30px;
      color: #015568;
      h4 {
        display: block;
        margin-block-start: 0;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      .move-to-signup {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &.otp {
    // height: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    .verification-code {
      font-weight: 700;
      font-size: 25px;
      color: #015568;
      margin-bottom: 16px;
    }
    .please-type {
      width: 230px;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      color: #015568;
      margin-bottom: 20px;
    }
    .otp-input-container {
      direction: ltr;
      width: 270px;
      height: 56px;
      left: 51px;
      top: 387px;
      background: rgba(217, 217, 217, 0.3);
      border: 1px solid #015568;
      border-radius: 28px;
      margin-bottom: 27px;
      .separator {
        height: 2em;
        margin-bottom: 5px;

        font-size: 20px;
        color: #015568;
      }
      input {
        width: 2em;
        height: 2em;

        font-size: 20px;
        color: #015568;
      }
    }
    .send-again-button {
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
      color: #015568;
      background: none;
      border: none;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &.signup {
    .select-lang-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .select-lang-info {
        width: 20%;
      }

      .lang-select {
        padding-top: 20px;
      }
    }
    .top-icon {
      background-image: url("../../assets/icons/hopetimize.svg");
      background-position: center;
      background-repeat: no-repeat;
      height: 100px;
    }
    .background-image {
      background-image: url("../../assets/icons/login.svg");
      background-position: center;
      background-repeat: no-repeat;
      height: 350px;
    }
    .info-container {
      // text-align: center;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 400px;
      margin-bottom: 10px;
      h4 {
        color: #015568;

        font-weight: 100;
        display: block;
        margin-block-start: 0;
        margin-block-end: 10px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        width: 320px;
        .purple-hopetimize {
          font-weight: bold !important;
          color: #9c1cff !important;
        }
        .purple-hopetimize-unbold {
          color: #9c1cff !important;
        }
      }
    }
    .content {
      flex-direction: column;
      height: fit-content;
      // top: 65%;
      position: relative;
      .input-line {
        display: flex;
        gap: 10px;
      }
      .input-container {
        margin-bottom: 10px !important;
      }
      .phone-icon-and-text-container {
        width: 80%;
        justify-content: center;
        margin-bottom: 8px;
        .your-mobile-phone-text {
          margin: 0 14px;
          font-size: 20px;
          color: #015568;
          // width: 200px;
          &.eg {
            font-size: 12px;
          }
        }
      }
      .PhoneInput {
        margin-bottom: 15px !important;
        width: 100%;
      }
      .PhoneInputCountry {
        width: 80px;
        height: 46px !important;
        background: #d9d9d9;
        border-radius: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          187.55deg,
          #d9d9d9 -15.08%,
          #f2f2f2 58.2%
        ) !important;
      }
      .PhoneInputInput {
        width: 230px !important;
        height: 46px !important;
        background: #d9d9d9;
        border-radius: 28px;
        border: none;
        font-size: 16px;
        padding: 0 10px;
        background: linear-gradient(
          187.55deg,
          #d9d9d9 -15.08%,
          #f2f2f2 58.2%
        ) !important;
      }
      .we-will-send-you {
        width: 500px;
        text-align: center;

        font-size: 16px;
        color: #4c5f71;
        margin-top: 11px;
        margin-bottom: 20px;
      }
      .error-message {
        margin-top: 10px;
      }
      .error {
        margin-top: -5px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
    .button {
      margin-top: 20px;
      width: 320px !important;
    }
    .not-register {
      display: flex;
      gap: 5px;
      line-height: 10px;
      margin-bottom: 30px;
      h4 {
        display: block;
        margin-block-start: 0;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      .move-to-signup {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 320px) {
  .login-container {
    &.phone {
      .top-icon {
        background-size: auto 30px !important;
        height: 50px !important ;
      }
      .background-image {
        background-size: auto 170px !important;
        height: 180px !important;
      }
    }
    &.signup {
      .top-icon {
        background-size: auto 30px !important;
        height: 50px !important ;
      }
      .background-image {
        background-size: auto 170px !important;
        height: 180px !important;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .login-container {
    &.phone {
      .select-lang-info {
        width: 30% !important;
      }
      .top-icon {
        background-image: url("../../assets/icons/hopetimize.svg");
        background-position: center;
        background-repeat: no-repeat;
        height: 100px;
      }
      .background-image {
        background-image: url("../../assets/icons/login.svg");
        background-position: center;
        background-repeat: no-repeat;
        height: 350px;
      }

      .content {
        .PhoneInput {
          width: 80% !important;
        }
        .button {
          width: 80% !important;
        }

        .PhoneInputInput {
          width: 155px !important;
          height: 37px !important;
          font-size: 12px;
        }
        .we-will-send-you {
          font-size: 12px !important;
          width: 270px !important;
        }
      }
    }
    &.otp {
      .please-type {
        font-size: 15px;
      }
    }
    &.signup {
      .select-lang-info {
        width: 30% !important;
      }
      .top-icon {
        background-image: url("../../assets/icons/hopetimize.svg");
        background-position: center;
        background-repeat: no-repeat;
        height: 100px;
      }
      .background-image {
        background-image: url("../../assets/icons/login.svg");
        background-position: center;
        background-repeat: no-repeat;
        height: 350px;
      }

      .content {
        margin-top: 20px !important;
        .PhoneInput {
          width: 320px !important;
        }
        .button {
          width: 80% !important;
        }
        .we-will-send-you {
          font-size: 12px !important;
          width: 270px !important;
        }
      }
    }
  }
}
