@import "../../../../../../../utils/colors.scss";

.steps-progress-container {
  flex-direction: column;
  position: fixed;
  z-index: 100;
  .rtl & {
    left: 20px;
  }
  .ltr & {
    right: 20px;
  }
  .progress-circle {
    align-self: flex-end;
    width: 40px;
    height: 40px;
    background: var(--category-background-color);
    border-radius: 50px;
    margin-bottom: 10px;
    .text {
      font-size: 14px;
      color: black;
      letter-spacing: 1px;
      font-weight: bold;
    }
  }
  .progress-ellipse-container {
    width: 57px;
    height: 21px;
    background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    border-radius: 50px;
    .rtl & {
      margin-right: auto;
    }
    .ltr & {
      margin-left: auto;
    }
    .completed-icon {
      height: 18px;
      color: #2a265f;
    }
    .progress-first-step {
      background: var(--category-background-color);
      height: 21px;
      width: 20px;
      .rtl & {
        border-radius: 0 50px 50px 0;
      }
      .ltr & {
        border-radius: 50px 0 0 50px;
      }
    }
    .progress-first-step::after {
      background: var(--category-background-color);
      height: 21px;
      width: 20px;
    }
    .progress-second-step {
      background: var(--category-background-color);
      height: 21px;
      width: 38px;
      border-radius: 50px 0 0 50px;
    }
    .progress-second-step::after {
      background: var(--category-background-color);
      height: 21px;
      width: 38px;
    }
    .progress-third-step {
      background: var(--category-background-color);
      height: 21px;
      width: 58px;
      border-radius: 50px !important;
    }
    .progress-third-step::after {
      border-radius: 3px;
      background: var(--category-background-color);
      height: 21px;
      width: 57px;
    }
    .progress-text {
      font-size: 10px;
      opacity: 0.6;
      letter-spacing: 1px;
    }
  }
}
