.archived-maps-container {
  .category-container {
    .category-header {
      justify-content: flex-start !important;
      .category-icon {
        width: 35px;
        height: 35px;
      }
      .category-name {
        flex-direction: column;
        font-weight: bold;
        letter-spacing: -0.5px;
        color: #014352;
        font-size: 20px;
        .rtl & {
          margin-right: 10px;
        }
        .ltr & {
          margin-left: 10px;
        }
      }
    }
  }
}
