.app-header {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70px;
  background: linear-gradient(180.63deg, #60df89 -29.47%, #00e8e1 124.33%);
  box-shadow: 0px 4px 4px rgba(111, 111, 111, 0.2);
  border-radius: 0 0 50% 50%;
  z-index: 10;
  .user-name {
    margin: 14px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    .rtl & {
      margin-right: auto;
    }
    .ltr & {
      margin-left: auto;
    }
    .status-icon {
      transform: translateY(3px);
      width: 15px;
      height: 15px;
      margin-right: 2px;
      margin-left: 2px;
    }
  }
}

@media (max-width: 320px) {
  .app-header {
    height: 60px;
  }
}
