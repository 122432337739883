.smart-goal-container {
  flex-direction: column;

  .title {
    margin: 20px 0 5px 0;

    font-weight: 700;
    font-size: 30px;
    line-height: 18px;
    text-align: center;
    color: #60df89;
  }
  .content {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #015568;
    b {
      // .rtl & {
      //   margin-left: 10px;
      // }
      font-weight: 700;
    }
  }
  .read-more-button {
    width: 100%;
    height: 47px;
    margin-bottom: 30px;
    svg {
      padding: 0 5px;
    }
  }
  .ok-button {
    width: 100%;
    height: 52px;
  }
}
