.contacts-modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px grey;
  overflow-y: scroll;
  max-height: 80%;
  width: 80%;
  z-index: 10000;
  .contacts-close-icon {
    position: fixed;
    z-index: 2;
    top: 5px;
    .ltr & {
      right: 5px;
    }
    .rtl & {
      left: 5px;
    }
    padding: 3px;
    color: #015568;
    cursor: pointer;
    &:hover {
      color: #868484;
    }
  }
}

@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    height: 90%;
  }
}
