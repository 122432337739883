.statistics-card-container {
  justify-content: flex-start !important;
  flex-direction: column;
  align-items: flex-end !important;
  width: 147px;
  height: 70px;

  border-radius: 53px;
  margin-bottom: 14px;
  padding: 5px;
  color: #014352;

  p {
    padding: 0;
    margin: 0;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 16px;
    }

    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #014352;
      margin: auto;
      position: relative;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      margin-bottom: 10px;
      .text {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .flex {
    height: 70px;
    .ltr & {
      padding: 0 5px 0 10px;
    }
    .rtr & {
      padding: 0 10px 0 5px;
    }
    h2 {
      margin: 0 5px !important;
      font-size: 16px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .statistics-card-container {
    flex-direction: column;
  }
}
