.new-content-alert-container {
  .new-content-alert-title {
    padding-bottom: 20px;
  }

  .select-days-container {
    display: flex;
    justify-content: center;
  }

  .select-hour-container {
    display: flex;
    justify-content: center;
  }

  .new-content-alert-info {
    display: flex;
    justify-content: center;
  }

  .save-container {
    position: absolute;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    bottom: 0;
    .rtl & {
      right: 20%;
    }
    .ltr & {
      left: 20%;
    }
    .save-job {
      margin: 2px;
    }
    .send-now {
      margin: 2px;
    }
  }
}
