.remove-booster-popup-container {
  padding: 0 15px;
  .question {
    text-align: center;
  }
  margin: 0 20px;
  .remove-booster-content {
    table {
      word-break: break-word !important;
      td {
        color: #015568;
        h4 {
          margin: 0;
          padding: 0;
          display: inline;
        }
      }
    }
  }
  .flex {
    justify-content: space-around !important;
    .popup-button {
      width: 100px;
    }
    margin-bottom: 10px;
  }
}
