.input-container {
  margin-bottom: 20px;
  .active {
    outline: none;
    border: 2px solid red;
    box-shadow: 0 0 10px #719ece;
    border-radius: 30px;
  }
  .input {
    width: 100%;
    // height: 36px;
    overflow: hidden;
    max-height: 400px;
    background: linear-gradient(187.55deg, #d9d9d9 -15.08%, #f2f2f2 58.2%);
    box-shadow: 0px 4px 4px rgba(111, 111, 111, 0.2);
    border-radius: 30px;
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    // overflow-y: auto !important;
    -ms-overflow-style: none !important; /* Internet Explorer 10+ */
    scrollbar-width: none !important; /* Firefox */
    -webkit-scrollbar {
      display: none !important; /* Safari and Chrome */
    }
    &.disabled {
      pointer-events: none;
      filter: brightness(0.95);
    }
    textarea.disabled {
      pointer-events: initial !important;
    }
    textarea {
      margin: auto;
      // overflow-y: initial !important;
      resize: none;
      overflow: hidden;
      max-height: 400px;
      border: 0;
      background: transparent;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      width: 100%;
      color: #014352;
      padding-right: 10px;
      &:focus {
        outline: none;
        border: none;
      }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .icon {
      height: 47px;
      &.start {
        .rtl & {
          margin-right: 12px;
          margin-left: 12px;
        }
        .ltr & {
          margin-left: 12px;
          margin-right: 12px;
        }
      }
      &.end {
        .rtl & {
          margin-left: 16px;
          margin-right: auto;
        }
        .ltr & {
          margin-right: 16px;
          margin-left: auto;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .input.disabled {
    padding-top: 5px;
  }
}
