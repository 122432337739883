.checkbox-container {
  align-self: flex-start;
  .checkbox {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #d9d9d9;
    border-radius: 50%;
    &.checked {
      background-color: white;
      &::before {
        content: url("../../assets/icons/v.svg");
        transform: scale(0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
      }
    }
  }
  .label {
    font-size: 18px;
    color: #014352;
    margin-top: 2px;
    .rtl & {
      margin-right: 14px;
    }
    .ltr & {
      margin-left: 14px;
    }
    &.checked {
      font-weight: bold;
    }
  }
}
