.community-container {
  .card {
    flex-direction: column;
    width: 315px;
    min-height: max-content;
    color: #014352;
    background: linear-gradient(0deg, #f4f4f4 0%, #ffffff 100%);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    text-align: left;
    padding: 5px 10px 20px 10px;
    margin: 20px 0 30px 0;
    font-size: 14px;
    line-height: 15px;
    color: #015568;
    .title {
      margin-top: 15px;
      &:hover {
        text-decoration: underline;
      }
    }
    .body {
      margin: 0;
      p {
        margin: 10px 0;
        text-align: start;
        text-decoration: none;
        color: black;
        line-height: 20px;
      }
      &:hover {
        text-decoration: underline;
      }
      .external-link-icon {
        .rtl & {
          float: left;
        }
        .ltr & {
          float: right;
        }
      }
    }

    .flex {
      justify-content: flex-start;
      width: 80% !important;
      h2 {
        line-height: normal;
        padding-top: 5px;
      }
      svg {
        .rtl & {
          margin: 0 0 0 10px;
        }
        .ltr & {
          margin: 0 10px 0 0;
        }
      }
    }
  }
}
