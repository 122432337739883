.booster-questions-popup-container {
  flex-direction: column;
  .header-container {
    margin-top: 33px;
    flex-direction: column;
  }
  .footer-buttons {
    justify-content: space-between;
  }

  textarea {
    width: 100%;
  }
  .did-great-container,
  .share-container {
    margin: 20px 0;
    background-color: black;
    color: white;
    h3,
    p {
      margin: 0;
      padding: 0;
    }
  }

  .steps-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .step-dot {
      display: inline-block;
      width: 5px;
      height: 5px;
      background: none;
      border: 2px solid #ccc;
      border-radius: 50%;
      margin: 0px 3.5px;

      &.full {
        background: #ccc;
      }
    }
  }
}
