.rating-bar {
  padding-top: 10px;
  .input-title-container {
    margin-bottom: 10px !important;
  }
  .slider-wrapper {
    width: 95%;
    height: 300px;
    margin: 0;
    &:focus {
      background-color: red;
    }
  }
  //libray clasess - start
  .rc-slider {
    .rc-slider-rail {
      top: -10px;
      background-color: #d9d9d9;
      height: 31px;
      width: 105%;
      border-radius: 30px;
    }
    .rc-slider-handle {
      background: linear-gradient(
        180deg,
        #e6e6e6 0%,
        #f4f4f4 57.35%,
        #ffffff 100%
      );
      border: none;
      height: 32px;
      width: 32px;
      top: -5px;
      z-index: 2;
    }
    .rc-slider-handle:last-child {
      transform: translateX(0%) !important;
    }
    .rc-slider-mark-text-active {
      z-index: 3 !important;
      p {
        position: absolute;
        top: -20px;
        background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        // text-fill-color: transparent;
      }
    }
    .rc-slider-track {
      position: absolute;
      height: 31px;
      top: -10px;
      background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
      border-radius: 30px;
      z-index: 0;
      right: 50px;

      .ltr & {
        left: -20px;
      }
      .rtl & {
        right: -20px;
      }
    }
  }
  .slider-wrapper {
    height: max-content !important;
  }
  //libray clasess - end

  p {
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    // color: black;
    font-weight: 700;
    margin: 0;
  }

  .headlines {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ltr & {
      margin-left: 10px;
    }
    .rtl & {
      margin-right: 10px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      padding: 5px 6px;
    }
  }
}
