.media-center-container {
  padding-top: 100px;
  .creates-container {
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
    .create-content-button {
      width: 10%;
      margin: 2px;
    }
    .create-country-button {
      width: 10%;
      margin: 2px;
    }
    .set-new-content-alert {
      width: 10%;
      margin: 2px;
    }
  }

  .search-openner {
    cursor: pointer;
  }

  .contents-filters-container {
    height: 500px;
    .ltr & {
      margin-left: 10px;
    }
    .rtl & {
      margin-right: 10px;
    }

    .text-filters {
      margin-top: 10px;
      .advanced-filters-arrow {
        margin-right: 5px;
        margin-left: 5px;
      }
    }

    .filters-checkbox {
      margin-bottom: 10px;
      .filters-table {
        .filter-checkbox {
          width: 200px;
          .rtl & {
            text-align: right;
          }
        }
      }
      .filter-checkbox {
        margin: 0px 20px 20px 20px;
      }
    }

    .submit-filters {
      position: absolute;
      bottom: 20px;
    }
  }

  .active-filter {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .type-filters {
    display: flex;
    flex-wrap: wrap;
  }
  .filters-checkbox {
    display: flex;
    align-items: flex-start;
    .filter-checkbox {
      margin: 0px 20px 20px 20px;
    }
  }

  .quick-remove-search-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    .quick-remove-search-item {
      background: linear-gradient(180deg, #ffffff 0%, #dddddd 100%);
      color: #c3c3c3;
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      border-radius: 20px 20px 20px 20px;
      align-items: center;
      .quick-remove-search-item-x-icon {
        .ltr & {
          margin-right: 5px;
        }
        .rtl & {
          margin-left: 5px;
        }
      }
    }
    .quick-remove-search-str {
      background: linear-gradient(180deg, #ffffff 0%, #dddddd 100%);
      color: #c3c3c3;
      min-width: 100px;
      height: 30px;
      display: flex;
      justify-content: center;
      border-radius: 20px 20px 20px 20px;
      align-items: center;
      padding-right: 5px;
      padding-left: 5px;
      .quick-remove-search-item-x-icon {
        .ltr & {
          margin-right: 5px;
        }
        .rtl & {
          margin-left: 5px;
        }
      }
    }
  }

  .countries-buttons-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .country-button {
      font-size: small;
      width: 150px;
      margin: 2px;
    }
    .country-button-selected {
      font-size: small;
      width: 150px;
      margin: 2px;
      color: blue;
    }
    .country-delete-restore {
      &:hover {
        cursor: pointer;
      }
      .ltr & {
        margin-left: 20px;
      }
      .rtl & {
        margin-right: 20px;
      }
    }
  }
  .popup-button {
    cursor: pointer;
  }
  .font-button {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .media-center-container {
    .creates-container {
      .create-content-button {
        width: 20%;
        margin: 2px;
        font-size: small;
      }
      .create-country-button {
        width: 20%;
        margin: 2px;
        font-size: small;
      }
      .set-new-content-alert {
        width: 20%;
        margin: 2px;
        font-size: small;
      }
    }
    // .go-to-container {
    //   .scroll-to-button {
    //     width: 20%;
    //     margin: 2px;
    //     font-size: small;
    //   }
    // }
  }
}
