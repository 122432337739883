.edit-custom-category-popup-container {
  width: 100%;
  height: 300px;

  flex-direction: column !important;
  justify-content: space-between;

  .popup-title-container {
    h2 {
      text-align: center !important;
    }
  }
  .input-container {
    margin: 50px 5px 100px 5px;
  }
  .flex {
    .popup-button {
      width: 150px;
      margin: 0 5px 10px 5px;
    }
  }
}
