.select-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-bottom: 20px;
  &.focus {
    outline: none;
    border: 2px solid red;
    border-radius: 30px;
  }
  .required-field {
    color: red;
    font-size: 10px;
    margin-bottom: 0;
  }
  .select {
    min-height: 36px;
    font-size: 14px;
    padding: 5px 10px;
    background: #e7e7e7;
    border-radius: 22px !important;
    text-align: start;
    .fa-selected {
      background: white;
    }
    .v-icon {
      .rtl & {
        margin-left: 10px;
      }
      .ltr & {
        margin-right: 10px;
      }
    }
    .option-conatiner {
      display: flex;
      align-items: center;
      .option-checkbox {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background-color: #d9d9d9;
        &.checked {
          background-color: white;
          &::before {
            content: url("../../assets/icons/v.svg");
            transform: scale(0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2px;
          }
        }
      }
      .option-label {
        font-size: 14px;
        color: #014352;
        margin-top: 2px;
        .rtl & {
          margin-right: 10px;
        }
        .ltr & {
          margin-left: 10px;
        }
        &.checked {
          font-weight: bold;
        }
      }
    }
  }
}
