.steps-buttons-container {
  margin-bottom: 30px;
  .step-in-goal {
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    text-align: start;
    margin-bottom: 20px;
    .start-setting-button {
      width: 150px;
    }
    .break-words {
      width: 100%;
    }
  }

  .break-words {
    min-width: 70%;
  }
  .step-button {
    min-height: 37px;
    max-height: max-content;
    width: 250px;
    border-radius: 30px;
    justify-content: space-evenly;
    &.edit {
      background: linear-gradient(180deg, #00e8e1 0%, #60df89 100%);
    }
    &.create {
      background: linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%);
    }
    span {
      font-size: 14px;
    }
    svg {
      padding: 0 5px;
    }
  }
}
