.selected-category-popup-container {
  width: 100%;
  height: 100%;
  background-image: url("../../../../../../assets/icons/location-blue.svg"),
    url("../../../../../../assets/icons/cloud.svg"),
    url("../../../../../../assets/icons/cloud.svg"),
    url("../../../../../../assets/icons/cloud.svg"),
    url("../../../../../../assets/icons/person4.svg"),
    url("../../../../../../assets/icons/path.svg");
  background-position: 148px 156px, 137px 140px, 77px 202px, 230px 212px,
    140px 269px, 147px 243px;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
    no-repeat;
  background-size: 73px 95px, 102px 30px, 66px 20px, 66px 20px, auto, 74px 126px;
  width: 335px;
  height: 442px;
  h2.popup-title.small {
    margin-top: 30px;
  }
}
.popup-bottom-bottons-container {
  position: absolute !important;
  bottom: 20px !important;
}
