.user-media-center-container {
  .user-contents-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .open-booster-popup {
    width: 42px;
    height: 42px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    padding: 10px;
    z-index: 1000000;
    box-shadow: 18px 0 20px -15px rgba(184, 180, 180, 0.75),
      -18px 0 20px -15px rgba(184, 180, 180, 0.75),
      0 18px 20px -15px rgba(184, 180, 180, 0.75);
    border-radius: 50%;
  }

  .search-icon-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid #404346;
    border-radius: 50px 50px 50px 50px;
    width: 85%;
    .ltr & {
      margin-left: 20px;
    }
    .rtl & {
      margin-right: 20px;
    }
    .search-icon {
      margin-top: 5px;
      width: 25px;
      height: 25px;
    }

    h2 {
      color: #404346;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    h3 {
      color: #404346;
      margin-top: 7.5px;
      margin-bottom: 5px;
    }
  }

  .bookmark-filter {
    display: flex;
    gap: 20px;
    justify-content: center;
    font-size: 18px;
    margin-bottom: -30px;
    transform: translateY(-15px);
  }
  .contents-filters-container {
    height: 500px;
    .ltr & {
      margin-left: 10px;
    }
    .rtl & {
      margin-right: 10px;
    }

    .text-filters {
      margin-top: 10px;
      .advanced-filters-arrow {
        margin-right: 5px;
        margin-left: 5px;
      }
    }

    .filters-checkbox {
      margin-bottom: 10px;
      .filters-table {
        .filter-checkbox {
          width: 200px;
          .rtl & {
            text-align: right;
          }
        }
      }
      .filter-checkbox {
        margin: 0px 20px 20px 20px;
      }
    }

    .submit-filters {
      position: absolute;
      bottom: 20px;
    }
  }
  .quick-remove-search-container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    .quick-remove-search-item {
      background: linear-gradient(180deg, #ffffff 0%, #dddddd 100%);
      color: #c3c3c3;
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      border-radius: 20px 20px 20px 20px;
      align-items: center;
      .quick-remove-search-item-x-icon {
        .ltr & {
          margin-right: 5px;
        }
        .rtl & {
          margin-left: 5px;
        }
      }
    }
    .quick-remove-search-str {
      background: linear-gradient(180deg, #ffffff 0%, #dddddd 100%);
      color: #c3c3c3;
      min-width: 100px;
      height: 30px;
      display: flex;
      justify-content: center;
      border-radius: 20px 20px 20px 20px;
      align-items: center;
      padding-right: 5px;
      padding-left: 5px;
      .quick-remove-search-item-x-icon {
        .ltr & {
          margin-right: 5px;
        }
        .rtl & {
          margin-left: 5px;
        }
      }
    }
  }
}
