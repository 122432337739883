.contact-upload-container {
  flex-direction: column;
  .popup-title-container {
    position: fixed !important;
  }
  .contact {
    margin-bottom: 30px;
    width: 100%;
    border-radius: 12px;
    height: 40px;
    justify-content: flex-start;
    display: flex;
    padding: 10px 5px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    cursor: pointer;
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
      box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: #fff;
    &:active {
      background: #6eb9f7;
      background-size: 100%;
      border-radius: 12px;
    }
  }
  .contact-upload-btn-container {
    padding-bottom: 50px;
    .contact-upload-btn {
      width: 60%;
      .fa-contact {
        position: absolute;
        .rtl & {
          margin-left: 130px;
        }
        .ltr & {
          margin-right: 130px;
        }
      }
      .rtl & {
        margin-left: 300px;
        float: right;
      }
      .ltr & {
        margin-right: 300px;
        float: left;
      }
    }
  }
}
