.view-content-video-container {
  .video-conatiner {
    .rtl & {
      margin-right: 20px !important;
    }
  }
  iframe {
    margin-top: 50px;
    min-height: 200px;
    height: 200px;
  }
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h5 {
    font-size: 2vh;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h6 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .info-container {
    margin-top: 60px;
    .ltr & {
      margin-left: 20px;
    }
    .rtl & {
      margin-right: 20px;
    }
    .content-date {
      color: rgb(182, 182, 182);
      font-size: 2vh;
    }
    .content-title {
      color: #0d4253;
      font-size: 2.75vh;
    }
    .content-desription {
      color: #0d4253;
      width: 225px;
      word-wrap: break-word;
    }
  }

  .booster-icon {
    margin-top: 10px;
    width: 10%;
  }

  .share-icon {
    margin-top: 10px;
    .ltr & {
      margin-left: 10px;
    }
    .rtl & {
      margin-right: 10px;
    }
    width: 8%;
  }
}
