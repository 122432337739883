.step-details-container {
  .step-pin-position {
    position: absolute;
    // background-color: yellow;
    z-index: 4002;
    &.one {
      width: 10%;
      height: 8%;
      top: 55%;
      left: 31%;
    }
    &.two {
      width: 10%;
      height: 8%;
      top: 44%;
      left: 75%;
    }
    &.three {
      width: 10%;
      height: 6%;
      top: 30%;
      left: 55%;
    }
  }
  .step-obstacle-position {
    position: absolute;
    // background-color: yellow;
    z-index: 4002;
    &.one {
      width: 32%;
      height: 11%;
      top: 66%;
      left: 21%;
    }
    &.two {
      width: 26%;
      height: 9%;
      top: 49%;
      left: 51%;
    }
    &.three {
      width: 10%;
      height: 5%;
      top: 36%;
      left: 60%;
    }
  }
  .step-details {
    position: absolute;
    z-index: 4002;
    background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
    border-radius: 50px;
    .text {
      max-width: 70%;
      text-align: center;
      margin: 2px 0;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    &.one {
      width: 100px;
      top: 50%;
      left: 22%;
      font-size: 10px;
    }
    &.two {
      width: 80px;
      top: 40%;
      left: 77%;
      font-size: 10px;
    }
    &.three {
      width: 90px;
      top: 26%;
      left: 56%;
      font-size: 10px;
    }
  }
}
