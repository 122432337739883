.settings-container {
  margin-top: 20px;
  text-align: center;
  .delete-user-modal-container {
    .popup-children {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      .delete-user-item {
        width: 90%;
      }
      .delete-user-buttons {
        margin-bottom: 10px !important;
        justify-content: space-around !important;
        width: 100%;
        .popup-button {
          width: 120px;
        }
      }
    }
  }
  h1 {
    text-align: center;
  }

  h4,
  p {
    margin: 0;
    padding: 0;
  }
  label {
    display: block;
    font-size: 0.83em;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .button.flex {
    width: 100%;
  }

  .profile-pic-container {
    .profile-pic-info {
      .ltr & {
        padding-right: 40px;
      }
      .rtl & {
        padding-left: 40px;
      }
    }
  }

  .language-select-container {
    .language-select {
      width: 200%;
    }
  }

  .notifications-container {
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .delete-user-container {
    .input-title-container .title {
      color: red;
    }
    margin-top: 30px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .trash-icon {
      margin-top: -5px;
      font-size: 30px;
      color: red;
      .rtl & {
        margin-left: -30px;
      }
      .ltr & {
        margin-right: -30px;
      }
    }
  }

  .notification-container {
    flex-direction: column;
    align-items: flex-start !important;

    .flex {
      justify-content: flex-start;

      margin: 15px 0 0 0;
    }

    // svg {
    //   path {
    //     fill: red;
    //   }
    // }
  }

  .country-container {
    width: 300px;
    justify-content: space-between;
  }
}
