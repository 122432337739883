.animated-map-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #69e19e;
  .fade-background {
    width: 100%;
    height: 100%;
    z-index: 3999;
    position: absolute;
    opacity: 0.5;
    background: linear-gradient(rgba(56, 57, 207, 0.1909357492997199));
  }
}

@media only screen and (max-width: 320px) {
  .left-bottom-rock {
    margin-left: -30px;
  }
  .rigth-bottom-rock {
    margin-left: 30px;
  }
}
