@import "../../../../../../utils/colors.scss";
.top-header-container {
  .top-header-details-container {
    flex-direction: column;
    position: absolute;
    width: 150px;
    top: 5%;
    left: 30%;
    z-index: 4002;
    border-radius: 50px;
    .name {
      // position: relative;
      width: 150px;
      // margin-bottom: 5px;
      // top: 8%;
      // left: 30%;
      z-index: 4002;
      .text {
        width: fit-content;
        color: white;
        margin: 2px 0;
        letter-spacing: 1px;
      }
    }
    .main-goal-details {
      // position: absolute;
      width: 170px;
      // top: 12%;
      // left: 30%;
      z-index: 4002;
      background: linear-gradient(0deg, #e6e6e6 0%, #ffffff 100%);
      border-radius: 50px;
      .text {
        max-width: 80%;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        margin: 4px 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 140px;
        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .archived-container {
    position: absolute;
    top: -10px;
    width: 100%;
    .archived {
      text-align: center;
      width: 100px;
      background-color: #f44336;
      color: white;
      padding: 0 10px;
      border-radius: 50px;
    }
  }

  .completed-ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    &::before,
    &::after {
      position: absolute;
      z-index: -1;
      content: "";
      display: block;
      border: 5px solid #2980b9;
    }
    span {
      top: 30px;
      position: absolute;
      display: block;
      width: 225px;
      padding: 15px 0;
      // background-color: #3498db;
      background: var(--category-background-color);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      color: #fff;
      font: 700 18px/1 "Lato", sans-serif;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      text-transform: uppercase;
      text-align: center;
    }
    &::before,
    &::after {
      border-top-color: transparent;
      border-left-color: transparent;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }

    .rtl & {
      top: -10px;
      left: -10px;
      &::before {
        right: 0;
      }
      &::after {
        left: 0;
      }
      span {
        right: -25px;
        top: 30px;
        transform: rotate(-45deg);
      }
    }
    .ltr & {
      top: -10px;
      right: -10px;
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
      span {
        left: -25px;
        transform: rotate(45deg);
      }
    }
  }

  .help-icon {
    position: absolute;
    top: 10px;
    height: 30px;
    z-index: 4002;
    color: #00008b;
    .rtl & {
      right: 90%;
    }
    .ltr & {
      right: 10px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
