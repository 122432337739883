.basic-info-container {
  margin: 20px 0 0 0;
  .intro-text {
    color: #015568;
    font-weight: bold;
  }
  .email-icon {
    height: 17px !important;
    color: #60df89;
  }

  .basic-info-step-dots {
    bottom: -40px;
    position: relative;
  }
}
