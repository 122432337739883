.went-well-conatiner {
  flex-direction: column;
  margin-top: 40px;
  .icon {
    position: relative;
    align-self: flex-start;
    margin-bottom: 20px;
    .ltr & {
      left: 85px;
    }
    .rtl & {
      right: 85px;
    }
  }
  .continue-button {
    width: 70%;
  }
}
