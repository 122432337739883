.booster-questions-container {
  flex-direction: column;
  height: 403px;
  background-image: url("../../../../assets/icons/location.svg"),
    url("../../../../assets/icons/cloud.svg"),
    url("../../../../assets/icons/cloud.svg"),
    url("../../../../assets/icons/cloud.svg"),
    url("../../../../assets/icons/person1.svg"),
    url("../../../../assets/icons/path.svg");
  background-position: 131px 105px, 116px 87px, 54px 143px, 218px 164px,
    120px 210px, 130px 192px;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
    no-repeat;
  background-size: 73px 95px, 102px 30px, 66px 20px, 66px 20px, auto, 74px 126px;
  .headers {
    flex-direction: column;
    margin-top: 20px;
  }
  .flex {
    justify-content: space-around !important;
  }
  .footer-buttons {
    position: relative;
    top: 290px;
    justify-content: space-evenly;
  }
}
