.add-to-calendar-button-container {
  background: linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%);
  min-height: 37px;
  max-height: max-content;
  width: 126px;
  border-radius: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: none;
  margin: 5px;
  a,
  span {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
    color: #015568;
    width: 100%;
    justify-content: space-around !important;
  }
  .calander-wrapper {
    background: linear-gradient(90deg, #00e37f 3%, #00ebe1 100%);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center !important;
  }
  svg {
    margin: 5px;
  }
}
