@font-face {
  // font-family: "FbTypographEng";
  @font-face {
    font-family: "FbTypographEng";
    src: local("AvertaBold"),
      url("./assets/fonts/FbTypograph2-Regular.otf") format("truetype");
  }

  // src: local("FbTypographEng"),
  //   url(./assets/fonts/FbTypographEng-REGULAR.ttf) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
* {
  font-family: "FbTypographEng";
}
.grecaptcha-badge {
  opacity: 0;
}
.app {
  height: 100vh;
  &.rtl {
    text-align: right;
    direction: rtl;
  }
  &.ltr {
    text-align: left;
    direction: ltr;
  }
  main {
    margin: -50px 20px 10px 20px;
    min-height: calc(100vh - 70px);
    // padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex-popup-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .popup-title-container {
      margin-top: 30px;
    }
  }
  .hidden {
    visibility: hidden;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.7;
  }
  .error {
    color: red !important;
    bottom: 5px;
    position: relative;
    font-size: 12px;
    font-weight: bold;
  }
  :root {
    --category-background-color: #1e90ff;
  }
}

.row {
  width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  // margin-top: 5px;
  // justify-content: center;
}

/* 1/12 */
.row-1 {
  height: 8.33%;
}

/* 2/12 */
.row-2 {
  height: 16.66%;
}

/* 3/12 */
.row-3 {
  width: 25%;
}

/* 4/12 */
.row-4 {
  height: 33%;
}

.row-6 {
  height: 50%;
}

.row-9 {
  height: 66%;
}

.row-12 {
  height: 100%;
}

/* 1/12 */
.col-1 {
  width: 8.33%;
}

/* 2/12 */
.col-2 {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}

/* 3/12 */
.col-3 {
  width: 25%;
}

/* 4/12 */
.col-4 {
  flex: 0 0 33%;
  max-width: 33%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-12 {
  flex: 0 0 50%;
}
.break-words {
  word-wrap: break-word !important;
  display: block !important;
  min-width: 100%;
}
.description {
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #014352;
  .rtl & {
    text-align: right;
  }
  .ltr & {
    text-align: left;
  }
}
.italic {
  font-style: italic;
}
