.IframeContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  //   position: relative;
  //   .iframe {
  //     width: 100%;
  //     height: 100%;
  //     border: none;

  //     &.full_screen {
  //       position: fixed;
  //       top: 0;
  //       left: 0;
  //       //   width: 100vw;
  //       //   height: 100vh;
  //     }
  //   }
}
