.map-instructions-popup-container {
  display: flex;
  margin: 0 10px;
  justify-content: space-evenly;
  .text {
    line-height: 16px;
  }
  hr {
    width: 95%;
  }
  .remember-title {
    font-size: 22px;
    font-family: monospace;
    color: #015568;
    margin: 7px 0;
  }
  hr {
    position: relative;
    border: none;
    height: 6px;
    background: rgb(213, 212, 212);
  }
  .step-container {
    margin: 1px 20px;
    .text {
      width: 200px;
      .rtl & {
        text-align: right;
      }
      .ltr & {
        text-align: left;
      }
    }
    .tip-container {
      background: transparent;
    }
  }
  .step2-container {
    margin: 10px 5px;
    .text {
      width: 230px;
    }
    .tip-container {
      background: transparent;
    }
  }
  .buttons-container {
    width: 100%;
    margin-bottom: 10px;
    .button {
      width: 120px;
    }
  }
}
