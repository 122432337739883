.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  &.z-index {
    z-index: 250;
  }

  .popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
    animation: fadeIn 0.3s;
  }

  .popup {
    z-index: 5000;
    position: fixed;
    // text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // width: 80%;
    transition: all 0.1s;
    // padding: 10px 25px 30px 25px;
    // background: #fdfdfd;
    // box-shadow: 2px 2px 5px $medium-shadow;
    border-radius: 10px;
    animation: fadeInDownMargin 0.3s;
    background: linear-gradient(90deg, #ffffff 0%, #e5e5e5 85%);
    box-shadow: 3px 3px 12px #00000080;

    .popup-children::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    .popup-children::-webkit-scrollbar:vertical {
      width: 12px !important;
    }

    .popup-children::-webkit-scrollbar:horizontal {
      height: 12px !important;
    }

    .popup-children::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      border: 2px solid #ffffff;
    }

    .popup-children::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }

    .popup-children {
      width: 336px;
      overflow-y: auto;
      word-wrap: break-word;

      .close-icon {
        position: absolute;
        z-index: 2;
        top: 5px;
        .ltr & {
          right: 10px;
        }
        .rtl & {
          left: 10px;
        }
        padding: 3px;
        color: #015568;
        cursor: pointer;
        &:hover {
          color: #868484;
        }
      }
    }
    .popup-bottom-bottons-container {
      position: relative;
      padding: 15px 0 0 0;
      width: 100%;
      justify-content: space-evenly;
      cursor: pointer;
    }
    .x-button {
      float: right;
    }
    .buttons-container {
      button {
        margin: 10px;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .popup-container {
      width: 90%;
    }
  }
}
