.share-map-popup-container {
  margin: 40px 30px 30px 30px;
  flex-direction: column;
  .header {
    margin-bottom: 30px;
  }
  .share-with-container {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .choose-platform-container {
    margin-bottom: 20px;
  }
  .close-button {
    margin-top: 60px;
    width: 90%;
  }
}
