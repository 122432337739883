.create-step-card-container {
  .popup-titles-wrapper {
    margin: 0 0 20px 0;
  }

  .description {
    font-weight: 700;
    // font-size: 12px;
    // line-height: 12px;
    color: #015568;
    margin: 20px !important;
    // text-align: center;
    .rtl & {
      margin-right: 20px;
    }
    .ltr & {
      margin-left: 20px;
    }
    .pathway-number-name {
      text-decoration: underline;
    }
  }
  .inputs-container {
    flex-direction: column;
  }
  .input {
    height: 50px !important;
  }
  .create-date-container {
    display: flex;
    &.step1 {
      width: 330px !important;
      height: 50px !important;
    }
    &.step2 {
      width: 160px !important;
      height: 50px !important;
    }
  }
  .dateinput-and-calendar {
    align-items: flex-start;
    justify-content: space-between;
  }
  //   .date-time-container {
  //     width: 136px;
  //     margin: 0 !important;
  //   }
  .date-calendar {
    width: 100px;
    justify-content: space-between !important;
    padding: 0 !important;
  }
  .calendar-container {
    background: linear-gradient(180deg, #ffffff 0%, #e6e6e6 100%);
    min-height: 37px;
    max-height: max-content;
    // width: 330px;
    border-radius: 30px;
    // margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: none;
    height: 40px;
    a,
    span {
      text-decoration: none;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 22px;
      color: #015568;
      justify-content: space-around !important;
    }
    .calander-wrapper {
      background: linear-gradient(90deg, #00e37f 3%, #00ebe1 100%);
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center !important;
      .rtl & {
        margin-left: 5px;
      }
      .ltr & {
        margin-right: 5px;
      }
    }
    svg {
      background: #ffffff;
      width: 10px;
      height: 10px;
    }
  }
}
