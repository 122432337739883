.custom-category-instructions-popup-container {
  margin: 20px;
  flex-direction: column;
  .custom-category-description {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #015568;
    margin-top: 40px;
  }
  svg {
    margin-left: 20px;
    margin-bottom: 30px;
  }
}
